import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import Footer from "../Footer";
import Header from "../Header";
import Agents from "../Agents";
import RealEstateAgent from "../RealEstateAgent";
import FindHome from "../FindHome";
import Services from "../Services";
import Carousel from "../Carousel";
import Testimonials from "../Testimonials";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseUrl } from "../BaseUrl";
import Features from "../Features";
import ChatWidget from "../ChatWidget";
import Loader from "../Loader";
import LoadingModal from "../LoadingModal";
import AdvertisementSlider from "../AdvertisementSlider";
import CompanyCopyright from "../CompanyCopyright";
import Spinner from "../Spinner";

function Index1() {
  const sliderRef = useRef(null);
  const [properties, setProperties] = useState([]);
  const [userIP, setUserIP] = useState('');
  const navigate = useNavigate();
  

  useEffect(() => {
    const tokenKey = 'detailstoken';

    // Fetch and set token if not already present in local storage
    const fetchToken = async () => {
      if (!localStorage.getItem(tokenKey)) {
        try {
          const response = await fetch(`${BaseUrl}/senttoken`);
          const data = await response.json();
          if (data.Token) {
            localStorage.setItem(tokenKey, data.Token);
            // console.log("Token created and stored in local storage:", data.Token);
          } else {
            console.error("Token not received:", data);
          }
        } catch (error) {
          console.error("Error fetching token:", error);
        }
      }
    };

    fetchToken();

    // Fetch properties
    fetch(`${BaseUrl}/viewallproperties`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.properties)) {
          setProperties(data.properties);
        } else {
          console.error("API response is not an array:", data);
        }
      })
      .catch((error) => console.error("Error fetching properties:", error));

    // Fetch user's IP address
    const fetchUserIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error("Error fetching user's IP address:", error);
      }
    };

    fetchUserIP();
  }, []);

  const settings = {
    infinite: properties.length > 1,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 800,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleSeeDetails = (propertyId) => {
    const token = localStorage.getItem('detailstoken');
    const userId = localStorage.getItem('userId'); // Assuming you store userId in localStorage
    const email = localStorage.getItem('email'); // Assuming you store email in localStorage
    const timestamp = new Date().toISOString();
    const propertyUrl = `${window.location.origin}/property-details/${propertyId}`; // Construct property URL

   
    
      navigate(`/property-details/${propertyId}`);
   
  };

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      
      content.classList.add('slide-in-right');
    }
  }, []);


  return (
    <div className="content">
      <Header />
      <Carousel />
      {/* <Spinner/> */}
      {/* <div className="section">
        <div className="container">
          <div className="row mb-5 align-items-center">
            <div className="col-lg-6">
              <h2 className="font-weight-bold heading">Popular Properties</h2>
            </div>
            <div className="col-lg-6 text-lg-end">
              <p>
                <a
                  href="/properties"
                  className="btn btn-primary text-white py-3 px-4"
                >
                  View all properties
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider ref={sliderRef} {...settings}>
                {properties.length > 0 ? (
                  properties.map((property) => (
                    <div key={property.id} className="property-item" style={{ padding: '15px', margin: '0 10px' }}>
                      <a onClick={() => handleSeeDetails(property.id)} className="img" style={{ textDecoration: 'none', cursor: "pointer" }}>
                        <img
                          src={property.bannerimage_name}
                          alt="Property Image"
                          className="img-fluid"
                          style={{ width: '100%', height: '350px' }}
                        />
                      </a>
                      <div className="property-content">
                        <div className="price mb-2">
                          <span>&#8377; {property.price}</span>
                        </div>
                        <div>
                          <span className="d-block mb-2 text-black-50" style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '100%',
                              display: 'block',
                            }}>
                            {property.address}
                          </span>
                          <span className="city d-block mb-3">
                            {property.city}, {property.state}, {property.country}
                          </span>
                          <div className="specs d-flex mb-4">
                            <span className="d-block d-flex align-items-center me-3">
                              <span className="icon-bed me-2" />
                              <span className="caption">{property.bedrooms} beds</span>
                            </span>
                            <span className="d-block d-flex align-items-center">
                              <span className="icon-bath me-2" />
                              <span className="caption">{property.bathrooms} baths</span>
                            </span>
                          </div>
                          <button
                            onClick={() => handleSeeDetails(property.id)}
                            className="btn btn-primary py-2 px-3"
                            style={{ textDecoration: 'none' }}
                          >
                            See details
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No properties available</p>
                )}
              </Slider>
              <div className="slider-controls mt-4 text-center">
                <button onClick={handlePrev} className="btn btn-secondary" style={{ float: 'left', marginRight: '10px' }}>
                  Prev
                </button>
                <button onClick={handleNext} className="btn btn-secondary" style={{ float: 'right' }}>
                  Next
                </button>
                <div style={{ clear: 'both' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <Loader/> */}
      <AdvertisementSlider/>
      <Features/>
      {/* <Services /> */}
      <FindHome />
      {/* <div className="mobile-view">
      <CompanyCopyright/>
      </div> */}
      {/* <RealEstateAgent /> */}
      {/* <ChatWidget/> */}
      {/* <Agents /> */}
      {/* <Testimonials /> */}
      <Footer />
      {/* <LoadingModal/> */}
    </div>
  );
}

export default Index1;
