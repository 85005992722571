import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { BaseUrl } from "./BaseUrl";
import axios from "axios";
import Toast, { showToast } from "./Toast.jsx";

const paginationStyle = {
  display: "flex",
  listStyle: "none",
  padding: 0,
  justifyContent: "center",
  marginTop: "20px",
};

const pageItemStyle = {
  margin: "0 5px",
};

const pageLinkStyle = {
  display: "block",
  padding: "6px 16px",
  border: "1px solid #ddd",
  borderRadius: "4px",
  backgroundColor: "black",
  color: "#fff",
  textDecoration: "none",
  cursor: "pointer",
};

function MyPropertyContent() {
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 6;

  useEffect(() => {
    const fetchProperties = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const id = localStorage.getItem("userId");
        const response = await fetch(`${BaseUrl}/getmyproperties/${id}`);
        const data = await response.json();

        // Check if properties is an array or a single object
        if (Array.isArray(data.properties)) {
          setProperties(data.properties);
        } else if (
          typeof data.properties === "object" &&
          data.properties !== null
        ) {
          setProperties([data.properties]);
        } else {
          setProperties([]);
        }
      } catch (error) {
        console.error("Error fetching properties:", error);
        setError("Failed to fetch properties. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    const content = document.querySelector(".content");
    if (content) {
      content.classList.add("slide-in-right");
    }
  }, []);

  const handleDelete = async (propertyId) => {
    try {
      const response = await axios.put(
        `${BaseUrl}/deleteproperty/${propertyId}`
      );
      if (response.status === 200) {
        showToast.success("Property deleted successfully");
        // Refresh the list of properties
        setProperties(
          properties.filter((property) => property.id !== propertyId)
        );
      } else {
        showToast.danger("Failed to delete property");
      }
    } catch (error) {
      console.error("Error deleting property:", error);
      showToast.danger("An error occurred while deleting the property");
    }
  };

  const handleRejectReason = (reason) => {
    showToast.warning(`Reject Reason: ${reason}`);
  };

  const PropertyStatusBadge = ({ property }) => {
    let statusText, badgeClass;

    switch (property.status) {
      case 1:
        statusText = "Approved";
        badgeClass = "badge text-bg-warning";
        break;
      case 0:
        statusText = "Inactive/Close";
        badgeClass = "badge text-bg-danger";
        break;
      case -1:
        statusText = "Pending for Approval";
        badgeClass = "badge text-bg-primary";
        break;
      case -2:
        statusText = "Rejected";
        badgeClass = "badge text-bg-danger";
        break;
      default:
        statusText = "Unknown";
        badgeClass = "badge text-bg-secondary";
        break;
    }

    return (
      <div
        onClick={() =>
          property.status === -2 && handleRejectReason(property.reject_reson)
        }
      >
        <span className={badgeClass}>{statusText}</span>
      </div>
    );
  };

  const renderPropertyList = () => {
    const indexOfLastProperty = currentPage * propertiesPerPage;
    const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
    const currentProperties = properties.slice(
      indexOfFirstProperty,
      indexOfLastProperty
    );

    if (isLoading) {
      return <p>Loading properties...</p>;
    } else if (error) {
      return <p>{error}</p>;
    } else if (currentProperties.length === 0) {
      return <p>You haven't posted any properties yet.</p>;
    } else {
      return currentProperties.map((property) => (
        <div className="col-lg-4 mt-3 mb-3" key={property.id}>
          <div className="card">
            <div className="card-body">
              <div style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{display:'flex', cursor:'pointer'}}>
              {/* <i class="fa fa-info-circle" aria-hidden="true" style={{margin:'6px', color:'#fff' }}></i> */}
              <PropertyStatusBadge property={property} />
              </div>
              <div>
              <span
                className="property-id"
                style={{
                  marginLeft: "20px",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ID #{property.id}
              </span>
              </div>
              </div>
              <h6 className="card-subtitle mt-2 mb-2 text-white">
                {property.title}
              </h6>
              <small>
                {property.address} {property.zipcode} {property.reject_reson}
              </small>
              <br />
              <small>
                <b>&#x20B9; {property.price}</b>
              </small>
              <br />
              <Link
                to={`/edit-property/${property.id}`}
                className="btn btn-primary py-2 px-3 mt-2"
              >
                Edit details
              </Link>
              <button
                className="btn btn-primary py-2 px-3 mt-2"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  marginLeft: "40px",
                }}
                onClick={() => handleDelete(property.id)}
              >
                <i className="fa fa-trash"></i>
              </button>

              <div>
                {property.city === "Bangalore Rural" ||
                property.city === "Bangalore Urban" ||
                property.city === "Bengaluru" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      gap: "1px",
                      marginTop: "20px",
                      borderRadius: "8px",
                      border: "1px solid #fff",
                      padding: "15px",
                    }}
                  >
                    <Link
                      to="https://bwsajala.karnataka.gov.in/bwssb_onlinepg/quickpay.aspx"
                      style={{
                        textDecoration: "none",
                        color: "#007bff",
                        fontSize: "16px",
                        borderRadius: "4px",
                        textAlign: "left",
                      }}
                      target="_blank"
                    >
                      Pay Water Bill
                    </Link>
                    <Link
                      to="https://www.bescom.co.in/bescom/main/quick-payment"
                      style={{
                        textDecoration: "none",
                        color: "#007bff",
                        fontSize: "16px",
                        borderRadius: "4px",
                        textAlign: "left",
                      }}
                      target="_blank"
                    >
                      Pay Electricity Bill
                    </Link>
                    <Link
                      to="https://bbmptax.karnataka.gov.in/"
                      style={{
                        textDecoration: "none",
                        color: "#007bff",
                        fontSize: "16px",
                        borderRadius: "4px",
                        textAlign: "left",
                      }}
                      target="_blank"
                    >
                      Pay BBMP Property Tax
                    </Link>
                    {property.municipal_authority === "PANCHAYAT" && (
                      <Link
                        to="https://bsk.karnataka.gov.in/BSK/cs/loadOnlineServicesBeforeLogin"
                        style={{
                          textDecoration: "none",
                          color: "#007bff",
                          fontSize: "16px",
                          borderRadius: "4px",
                          textAlign: "left",
                        }}
                        target="_blank"
                      >
                        Pay Panchayath Property Tax
                      </Link>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(properties.length / propertiesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <Header />
      <div className="section content">
        <div className="container mt120">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold text-primary heading">
                Manage your Properties
              </h4>
            </div>
            <div className="col-lg-6 text-lg-end"></div>
          </div>
          <div className="row mb-5">
            <div class="col-lg-3 d-none d-lg-block d-md-block">
              <ul className="list-group tabcss">
                <li className="list-group-item ">
                  <Link to="/profile">Basic Profile</Link>
                </li>
                <li className="list-group-item ">
                  <Link to="/profile/shortlisted-properties">
                    Your Shortlisted Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/your-payments"> Your Payments</Link>
                </li>
                <li className="list-group-item active">
                  <Link to="/profile/yourproperties "> Your Properties</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/interested-yourproperties">
                    {" "}
                    Interested in your Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/rewards"> Your Rewards</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/tickets"> Your Support Tickets</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/referral"> Referral</Link>
                </li>
              </ul>
            </div>

            <div
              class="mb-4 d-md-none d-lg-none"
              style={{ textAlign: "right" }}
            >
              <div class="dropdown-center">
                <button
                  class="btn btn-primary btn1 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa fa-building"></i> Your Properties
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="/profile">
                      Basic Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item"
                      to="/profile/shortlisted-properties"
                    >
                      Your Shortlisted Properties
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/profile/your-payments">
                      Your Payments
                    </Link>
                  </li>
                  {/* <li>
                  <a class="dropdown-item" href="/profile/yourproperties">
                   
                  Your Properties
                  </a>
                </li> */}
                  <li>
                    <Link
                      class="dropdown-item"
                      to="/profile/interested-yourproperties"
                    >
                      {" "}
                      Interested in your Properties
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/profile/rewards">
                      Your Rewards
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/profile/tickets">
                      Your Support Tickets
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/profile/referral">
                      Referral
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <h6 className="font-weight-bold text-primary mb-3">
                You have already posted{" "}
                <span style={{ color: "red" }}> {properties.length}</span>{" "}
                properties
              </h6>
              <Link
                className="btn"
                style={{ background: "#feb406", color: "white" }}
                to="/addproperties"
              >
                Post Your Property
              </Link>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="buy">
                  <div className="row mt-3">{renderPropertyList()}</div>
                  <nav>
                    <ul className="pagination" style={paginationStyle}>
                      {pageNumbers.map((number) => (
                        <li
                          key={number}
                          className="page-item"
                          style={pageItemStyle}
                        >
                          <button
                            onClick={() => paginate(number)}
                            className="page-link"
                            style={pageLinkStyle}
                          >
                            {number}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MyPropertyContent;
