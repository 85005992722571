import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Header from "./Header";
import Footer from "./Footer";
import { BaseUrl } from "./BaseUrl";
import Toast, { showToast } from "./Toast.jsx";

function Rewards() {
  const [rewards, setRewards] = useState([]);
  const [walletAmount, setWalletAmount] = useState(0);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    // console.log("Fetching rewards for userId:", userId);

    axios.get(`${BaseUrl}/getrewards/${userId}`)
      .then((response) => {
        // console.log("Data fetched successfully:", response.data);
        if (response.data) {
          const { rewards, totalCoins } = response.data;
          setRewards(rewards || []);
          setWalletAmount(totalCoins || 0);
        } else {
          console.error("No rewards found in response:", response.data);
          setRewards([]);
          setWalletAmount(0);
        }
      })
      .catch((error) => {
        console.error("Error fetching rewards:", error);
        setRewards([]);
        setWalletAmount(0);
      });
  }, []);
  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const totalRewards = rewards.reduce((total, reward) => total + reward.coins, 0);

  const tableStyle = {
    marginTop: "20px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    overflow: "hidden"
  };

  const thStyle = {
    // backgroundColor: "#f8f9fa",
    color: "#333",
    fontWeight: "bold",
    textAlign: "left",
    padding: "12px"
  };

  const tdStyle = {
    padding: "12px",
    borderBottom: "1px solid #dee2e6",
  };

  return (
    <>
      <Header />
      <div className="section content">
        <div className="container mt120">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold text-primary heading">
                Your Rewards
              </h4>
            </div>
            <div className="col-lg-6 text-lg-end"></div>
          </div>

          <div className="row mb-5">
          <div class="col-lg-3 d-none d-lg-block d-md-block">
            <ul className="list-group tabcss">
              <li className="list-group-item ">
                <Link to="/profile">Basic Profile</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/shortlisted-properties">
                  Your Shortlisted Properties
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/your-payments"> Your Payments</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/yourproperties"> Your Properties</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/interested-yourproperties">
                  {" "}
                  Interested in your Properties
                </Link>
              </li>
              <li className="list-group-item active">
                <Link to="/profile/rewards"> Your Rewards</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/tickets"> Your Support Tickets</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/referral"> Referral</Link>
              </li>
            </ul>
            
          </div>

          <div class="mb-4 d-md-none d-lg-none" style={{textAlign:"right"}}>
            <div class="dropdown-center">
              <button
                class="btn btn-primary btn1 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fa fa-trophy"></i> Your Rewards
              </button>
              <ul class="dropdown-menu">
                <li>
                  <Link class="dropdown-item" to="/profile">
                    Basic Profile
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/shortlisted-properties">
                    Your Shortlisted Properties
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/your-payments">
                  
                    Your Payments
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/yourproperties">
                   
                  Your Properties
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/interested-yourproperties">
                 
                  {" "}
                  Interested in your Properties
                  </Link>
                </li>

                <li>
                  <Link class="dropdown-item" to="/profile/tickets">
                  Your Support Tickets
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/referral">
                  Referral
                  </Link>
                </li>
              </ul>
            </div>
          </div>
            <div className="col-lg-9">
              <h6 className="font-weight-bold text-primary mb-3">Your Rewards</h6>
              <div className="mb-4">
                <h6 className="font-weight-bold text-secondary">
                  Current Wallet Amount: <i className="fas fa-coins" style={{ marginRight: "8px", color: "yellow" }}></i> <span className="theme_yellow"> {walletAmount}</span>
                </h6>
              </div>
              {rewards.length === 0 ? (
                <p>No rewards found.</p>
              ) : (
                <table className="table" style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle} scope="col">#</th>
                      <th style={thStyle} scope="col">Date</th>
                      <th style={thStyle} scope="col"><i className="fas fa-coins" style={{ marginRight: "8px", color: "yellow" }}></i>Reward</th>
                      <th style={thStyle} scope="col">Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewards.map((reward, index) => (
                      <tr key={index}>
                        <td style={tdStyle} scope="row">{index + 1}</td>
                        <td style={tdStyle}>{formatDate(reward.created_date)}</td>
                        <td style={tdStyle}><i className="fas fa-coins" style={{ marginRight: "8px", color: "yellow" }}></i>{reward.coins}</td>
                        <td style={tdStyle}>{reward.name}</td>
                      </tr>
                    ))}
                    {rewards.length > 0 && (
                      <tr>
                        <td style={{ ...tdStyle, textAlign: "right", fontWeight: "bold" }} colSpan="3">Total Rewards</td>
                        <td style={tdStyle}><i className="fas fa-coins" style={{ marginRight: "8px", color: "yellow" }}></i>{totalRewards}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Rewards;
