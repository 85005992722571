import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { BaseUrl } from "./BaseUrl";
import backgroundImage from "./bg1.jpg";

function Testimonials() {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/getalltestimonials`);
        if (response.data && response.data.results) {
          setTestimonialsData(response.data.results);
        } else {
          setTestimonialsData([]); // Ensure it's an empty array if no data
        }
      } catch (error) {
        console.error("Error fetching testimonials:", error);
        setTestimonialsData([]); // Handle error case by setting an empty array
      }
    };

    fetchTestimonials();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    // properties.length > 2
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // centerMode: properties.length >= 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextTestimonial = () => {
    sliderRef.current.slickNext();
  };

  const prevTestimonial = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div
      className="section sec-testimonials mt-5"
      style={{ margin: "2px", paddingTop: "3px", paddingBottom: "7rem" }}
    >
      <div className="container">
        <div className="row justify-content-center text-center mb-2 text-white">
          <div className="col-lg-12 mb-2">
            <h2 className="font-weight-bold heading text-primary mb-4 mb-md-0">
              What Our Customers Are Saying
            </h2>
            <p>
              Hear from our satisfied clients who have found their perfect homes
              with our help. Their experiences and stories speak to the quality
              and dedication we bring to every interaction.
            </p>
          </div>
        </div>

        {testimonialsData.length > 0 ? (
          <Slider {...settings} ref={sliderRef}>
            {testimonialsData.map((testimonial) => (
              <div
                className="testimonial"
                key={testimonial.id}
                style={{ textAlign: "center" }}
              >
                <div
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "auto",
                    border: "1px solid rgb(132 132 132)",
                    borderRadius: "6px",
                    boxShadow: "0px 3px 8px rgba(255, 255, 255, 0.5)", // White box shadow
                  }}
                >
                  <img
                    src={testimonial.image}
                    alt="Image"
                    className="img-fluid rounded-circle w-25 mb-2"
                    style={{
                      display: "inline-block",
                      width: "87px !important",
                      height: "87px",
                      marginTop: "10px",
                    }}
                  />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h3 className="h5 text-primary mb-2">
                      {testimonial.name} - &nbsp;
                    </h3>
                    <div className="rate">
                      {[...Array(testimonial.rating)].map((_, i) => (
                        <span key={i} className="icon-star text-warning"></span>
                      ))}
                    </div>
                  </div>
                  <blockquote style={{ fontSize: "small", margin: "10px" }}>
                    <p style={{ textAlign: "left", color: "#b9b9b9" }}>
                      {testimonial.description}
                    </p>
                  </blockquote>
                  <p style={{ color: "#feb406" }}>{testimonial.designation}</p>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p>No testimonials available.</p>
        )}
      </div>
      {/* <div className="col-md-6 text-md-end desktop-view">
            <div id="testimonial-nav">
              <span
                className="prev"
                onClick={prevTestimonial}
                data-controls="prev"
              >
                Prev
              </span>
              <span
                className="next"
                onClick={nextTestimonial}
                data-controls="next"
              >
                Next
              </span>
            </div>
          </div> */}
    </div>
  );
}

export default Testimonials;
