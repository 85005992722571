// Toast.jsx
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

// Helper functions for showing different types of toasts
export const showToast = {
  success: (message) => toast.success(message),
  warning: (message) => toast.warning(message),
  danger: (message) => toast.error(message),
  info: (message) => toast.info(message),
};

const Toast = () => {
  return <ToastContainer position="top-right" autoClose={3000} />;
};

export default Toast;
