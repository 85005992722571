import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../BaseUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backgroundImage from "../bg1.jpg";

const ExpertAgent = ({ id }) => {
  const [agents, setAgents] = useState([]);
  const categoryId = id;

  useEffect(() => {
    if (categoryId) {
      // Fetch agents data from the API based on category ID
      axios
        .get(`${BaseUrl}/getAgentsByCategory`, {
          params: { id: categoryId },
        })
        .then((response) => {
          if (response.data.success) {
            setAgents(response.data.results);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the agents data!", error);
        });
    }
  }, [categoryId]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div>
      {agents?.length > 0 && (
        <div className="section section-5">
          <div className="container">
            <div className="row justify-content-center text-center mb-2 text-white">
              <div className="col-lg-12 mb-2">
                <h2 className="font-weight-bold heading text-primary mb-4">
                  Meet Our Expert Agents
                </h2>
                <p>
                  Our dedicated agents are here to guide you through every step
                  of your real estate journey. With unmatched expertise and a
                  commitment to your success, they’ll help you find the perfect
                  property with ease.
                </p>
              </div>
            </div>
            <Slider {...settings}>
              {agents.map((agent) => (
                <div key={agent.id} className="px-3">
                  <div
                    className="h-100 person shadow-sm p-3 mb-5 rounded"
                    style={{
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src={agent.image}
                        alt="Agent"
                        className="img-fluid rounded-circle"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          marginTop: "-5px",
                        }}
                      />
                    </div>
                    <div className="person-contents mt-4">
                      <h4 className="mb-0 text-center text-white">
                        {agent.name}
                      </h4>
                      <span className="meta d-block mb-3">{agent.role}</span>
                      <p className="small text-center">{agent.description}</p>
                      <ul className="social list-unstyled list-inline dark-hover text-center mobile-width">
                        {agent.twitter_url && (
                          <li className="list-inline-item">
                            <a
                              href={agent.twitter_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="icon-twitter" />
                            </a>
                          </li>
                        )}
                        {agent.facebook_url && (
                          <li className="list-inline-item">
                            <a
                              href={agent.facebook_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="icon-facebook" />
                            </a>
                          </li>
                        )}
                        {agent.linkedin_url && (
                          <li className="list-inline-item">
                            <a
                              href={agent.linkedin_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="icon-linkedin" />
                            </a>
                          </li>
                        )}
                        {agent.instagram_url && (
                          <li className="list-inline-item">
                            <a
                              href={agent.instagram_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="icon-instagram" />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpertAgent;
