import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "./BaseUrl";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Toast, { showToast } from "./Toast.jsx";

function Refund() {
  const [refunds, setRefunds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      axios
        .get(`${BaseUrl}/getrefunddetails/${userId}`)
        .then((response) => {
          if (response.data.results) {
            setRefunds(response.data.results);
          }
        })
        .catch((error) => {
          setError("There was an error fetching the refunds!");
          console.error("There was an error fetching the refunds!", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError("No user ID found in local storage.");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const formatServiceNames = (services) => {
    if (Array.isArray(services)) {
      return services.join(", ");
    }
    return '';
  };

  const isMobile = window.innerWidth <= 600;
  
  return (
    <>
      <Header />
      <div className="section">
        <div className="container mt120 content">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold text-primary heading">
                Refund Details
              </h4>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-3 d-none d-lg-block d-md-block">
              <ul className="list-group tabcss">
                <li className="list-group-item ">
                  <Link to="/profile">Basic Profile</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/shortlisted-properties">
                    Your Shortlisted Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/your-payments"> Your Payments</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/yourproperties"> Your Properties</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/interested-yourproperties">
                    Interested in your Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/rewards"> Your Rewards</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/tickets"> Your Support Tickets</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/referral"> Referral</Link>
                </li>
              </ul>
            </div>

            <div
              className="mb-4 d-md-none d-lg-none"
              style={{ textAlign: "right", display: isMobile ? "block" : "none" }}
            >
              <div className="dropdown-center">
                <button
                  className="btn btn-primary btn1 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-credit-card"></i> Your Payments
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/profile">
                      Basic Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/profile/shortlisted-properties"
                    >
                      Your Shortlisted Properties
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/your-payments">
                      Your Payments
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/yourproperties">
                      Your Properties
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/profile/interested-yourproperties"
                    >
                      Interested in your Properties
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/rewards">
                      Your Rewards
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/tickets">
                      Your Support Tickets
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/referral">
                      Referral
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-9">
              <h6 className="font-weight-bold text-primary mb-3">Your Refund Details</h6>
              <div style={{ overflowX: "auto" }}>
                <table
                  className="table"
                  style={{
                    background: "transparent",
                    color: "white",
                    display: isMobile ? "none" : "table",
                  }}
                >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Payment Amount</th>
                      <th scope="col">Refund Amount</th>
                      <th scope="col">Refund Status</th>
                      <th scope="col">Refunded Service Names</th>
                      <th scope="col">Timestamp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {refunds.length > 0 ? (
                      refunds.map((refund, index) => (
                        <tr key={refund.id}>
                          <th scope="row">{index + 1}</th>
                          <td>{refund.payment_amount}</td>
                          <td>{refund.refund_amount}</td>
                          <td>{refund.refund_status}</td>
                          <td>{formatServiceNames(refund.refunded_service_names)}</td>
                          <td>{formatTimestamp(refund.timestamp)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No refunds found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {/* Mobile view table */}
                <div
                  style={{
                    display: isMobile ? "block" : "none",
                    borderBottom: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  {refunds.length > 0 ? (
                    refunds.map((refund, index) => (
                      <div
                        key={refund.id}
                        style={{
                          borderBottom: "1px solid #ddd",
                          padding: "10px",
                        }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          <strong>#</strong>: {index + 1}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Payment Amount</strong>: {refund.payment_amount}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Refund Amount</strong>: {refund.refund_amount}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Refund Status</strong>: {refund.refund_status}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Refunded Service Names</strong>: {formatServiceNames(refund.refunded_service_names)}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Timestamp</strong>: {formatTimestamp(refund.timestamp)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">No refunds found.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Refund;
