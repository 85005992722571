import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseUrl } from "../BaseUrl";
import Footer from "../Footer";
import Header from "../Header";
import LoginModal from "../LoginModal";
import UserForm from "./UserForm";
import LogVisit from "./LogVisit";
import axios from "axios";
import Toast, { showToast } from "../Toast.jsx";

const PropertyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [isFavourite, setIsFavourite] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [error, setError] = useState(null);
  const [showOwnerDetails, setShowOwnerDetails] = useState(false);

  const userId = localStorage.getItem("userId");
  const userEmail = localStorage.getItem("email");

  useEffect(() => {
    if (!userEmail) {
      setShowForm(true);
      setLoading(false);
      return;
    }

    // Fetch property details
    fetch(`${BaseUrl}/showproperty/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setProperty(data.property);
        } else {
          console.error("API response is not as expected:", data);
        }
      })
      .catch((error) =>
        console.error("Error fetching property details:", error)
      );

    // Check if the property is already a favourite
    if (userId) {
      fetch(
        `${BaseUrl}/showfavouritestatus?propertyId=${id}&userId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data.favourite_status !== undefined) {
            setIsFavourite(data.favourite_status === 1);
            // console.log(data.favourite_status);
          } else {
            console.error("Failed to fetch favourite status:", data);
          }
        })
        .catch((error) =>
          console.error("Error fetching favourite status:", error)
        )
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [id, userId, userEmail]);

  const [loginModalShow, setLoginModalShow] = useState(false);

  const handleClose = () => setLoginModalShow(false);

  const handleToggleFavourite = () => {
    if (!userId) {
      setLoginModalShow(true);
      return;
    }

    const newStatus = isFavourite ? 0 : 1;

    fetch(`${BaseUrl}/addtofavourites`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        propertyId: id,
        userId: userId,
        status: newStatus,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Property favourite status updated") {
          setIsFavourite(newStatus === 1);
          showToast.success("Favourite status updated successfully");
        } else {
          console.error("Failed to update favourite status:", data);
        }
      })
      .catch((error) =>
        console.error("Error updating favourite status:", error)
      );
  };

  const handleToggleOwnerDetails = async () => {
    if (!showOwnerDetails) {
      await fetchOwnerDetails(); // Fetch owner details only if they are not already shown
    }
    setShowOwnerDetails(!showOwnerDetails); // Toggle the visibility state
  };

  const fetchOwnerDetails = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/getownerdetails/${id}`);
      if (response.data.success) {
        setOwnerDetails(response.data.results[0]);
      } else {
        console.error("Failed to fetch owner details");
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
    }
  };

  const dayMapping = {
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday",
    6: "Sunday",
    7: "Everyday",
  };

  const getReadableDays = (days) => {
    return days
      .split(",")
      .map((day) => dayMapping[day.trim()])
      .join(", ");
  };

  const sliderSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getReadableValue = (key, value) => {
    const mapping = {
      property_type: ["Select", "Residential", "Commercial"],
      created_by: ["Select", "User", "Staff (Admin)"],
      property_status: ["Select", "Ready to Move In", "Under Construction"],
      parking_space: [
        "Select",
        "2 Wheeler",
        "4 Wheeler",
        "Both 2 Wheeler and 4 Wheeler",
        "No Parking Available",
      ],
      bhk_type: [
        "Select",
        "1 BHK",
        "2 BHK",
        "3 BHK",
        "4 BHK",
        "5 BHK",
        "+5 BHK",
      ],
      residential_type: [
        "Select",
        "Apartment/Flat",
        "Independent House/Villa",
        "Gated Community Villa",
        "Plot/Land",
      ],
      commertial_type: [
        "Select",
        "Office Space",
        "Shop/Showroom",
        "Warehouse/Godown",
        "Industrial Building",
        "Mall",
        "Theatre",
        "Land/Plot",
      ],
      furnishing_type: [
        "Select",
        "Fully Furnished",
        "Semi Furnished",
        "Non Furnished",
      ],
      property_for: ["Select", "Rent", "Sell", "Lease"],
      prefered_tenants: [
        "Select",
        "Anyone",
        "Bachelor Male",
        "Bachelor Female",
        "Family",
      ],
      preferred_business: ["Select", "IT", "Medical", "Press", "Resturent"],
      boundary_wall: ["Select", "Yes", "No"],
      visitor_parking_type: ["Select", "Unpaid", "Paid"],
      negotiable: ["Select", "No", "Yes"],
      water_supply: [
        "Select",
        "Corporation",
        "Borewell",
        "Available from Both Corporation and Borewell",
        "No Water Supply Available Now",
      ],
      available_visiting_days: [
        "Select",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Satarday",
        "Sunday",
        "Everyday",
      ],
    };

    return mapping[key] ? mapping[key][value + 1] : value;
  };

  const isValidField = (value) =>
    value !== -1 && value !== null && value !== "" && value !== "-1";

  const handleFormSubmit = async (formData) => {
    // Fetch the token from localStorage
    const detailstoken = localStorage.getItem("detailstoken");

    try {
      const response = await fetch(`${BaseUrl}/fillform`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Include the token in the headers if available
          ...(detailstoken && { Authorization: `Bearer ${detailstoken}` }),
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        // Adjust the success condition based on the returned data
        if (data.message === "Thank you, data submitted successfully") {
          localStorage.setItem("email", formData.email);
          showToast.success(data.message);
          // console.log("Data submitted successfully");
          setShowForm(false);
          LogVisit({ propertyId: id });
        } else {
          console.error("Failed to submit form data:", data.message || data);
          showToast.danger(data.message);
        }
      } else {
        console.error("Failed to submit form data:", data.message || data);
        showToast.danger(data.message);
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  return (
    <>
      <Header />
      {userEmail && <LogVisit propertyId={id} />}
      <div className="section" style={{ paddingTop: "10rem" }}>
        <div className="container">
          {loading ? (
            <p>Loading...</p>
          ) : showForm ? (
            <UserForm onSubmit={handleFormSubmit} />
          ) : property ? (
            <div className="row justify-content-between">
              <div className="col-lg-5">
                <p
                  className={`icon-heart ${
                    isFavourite ? "text-danger" : "text-white"
                  }`}
                  style={{
                    fontSize: "2rem",
                    marginTop: "13px",
                    marginLeft: "2%",
                    // zIndex: "99999999",
                    zIndex: "2",
                    position: "relative",
                  }}
                  onClick={handleToggleFavourite}
                ></p>
                <div
                  className="img-property-slide-wrap"
                  style={{ marginTop: "-66px" }}
                >
                  {property.images && property.images.length === 1 ? (
                    <div>
                      <img
                        src={property.images[0]}
                        alt="Property Image"
                        className="img-fluid"
                        style={{ width: "100%", height: "380px" }}
                      />
                    </div>
                  ) : property.images && property.images.length > 1 ? (
                    <Slider {...sliderSettings} className="img-property-slide">
                      {property.images.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            alt="Property Image"
                            className="img-fluid"
                            style={{ width: "100%", height: "380px" }}
                          />
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <p>No images available</p>
                  )}
                </div>
              </div>
              <div className="col-lg-7 property-item text-white">
                <h2 className="theme_yellow">{property.title}</h2>
                <p className="meta">
                  {property.city}, {property.state}, {property.country},{" "}
                  {property.zipcode},{property.address}
                </p>
                <h3 className="theme_yellow">&#8377; {property.price}</h3>
                <div class="probox">
                  <div class="row g-0">
                    <div class="col-lg-4 proboxdv">
                      <div class="row g-0">
                        <div class="col-2 valign">
                          <i class="fa fa-home fa-2x"></i>
                        </div>
                        <div class="col-10">
                          <div class="card-body">
                            <h6 className="theme_yellow">
                              {" "}
                              {getReadableValue(
                                "property_type",
                                property.property_type
                              )}
                            </h6>
                            <p class="card-text">Property Type</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 proboxdv">
                      <div class="row g-0">
                        <div class="col-2 valign">
                          <i class="fas fa-ruler fa-2x"></i>
                        </div>
                        <div class="col-10">
                          <div class="card-body">
                            <h6 className="theme_yellow">{property.sqft}</h6>
                            <p class="card-text">Sqft</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 proboxdv">
                      <div class="row g-0">
                        <div class="col-2 valign">
                          <i class="fa fa-building fa-2x"></i>
                        </div>
                        <div class="col-10">
                          <div class="card-body">
                            <h6 className="theme_yellow">
                              {" "}
                              {getReadableValue(
                                "property_for",
                                property.property_for
                              )}
                            </h6>
                            <p class="card-text">Property For</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 proboxdv">
                      <div class="row g-0">
                        <div class="col-2 valign">
                          <i class="fa fa-car fa-2x"></i>
                        </div>
                        <div class="col-10">
                          <div class="card-body">
                            <h6 className="theme_yellow">
                              {" "}
                              {getReadableValue(
                                "parking_space",
                                property.parking_space
                              )}
                            </h6>
                            <p class="card-text">Parking Space</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 proboxdv">
                      <div class="row g-0">
                        <div class="col-2 valign">
                          <i class="fa fa-wrench fa-2x"></i>
                        </div>
                        <div class="col-10">
                          <div class="card-body">
                            <h6 className="theme_yellow">
                              {" "}
                              {getReadableValue(
                                "furnishing_type",
                                property.furnishing_type
                              )}
                            </h6>
                            <p class="card-text">Furnishing Type</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 proboxdv">
                      <div class="row g-0">
                        <div class="col-2 valign">
                          <i class="fa-solid fa-person-digging fa-2x"></i>
                        </div>
                        <div class="col-10">
                          <div class="card-body">
                            <h6 className="theme_yellow">
                              {" "}
                              {getReadableValue(
                                "property_status",
                                property.property_status
                              )}
                            </h6>
                            <p class="card-text">Property Status</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 proboxdv">
                      <div class="row g-0">
                        <div class="col-2 valign">
                          <i class="fa fa-tint fa-2x"></i>
                        </div>
                        <div class="col-10">
                          <div class="card-body">
                            <h6 className="theme_yellow">
                              {" "}
                              {getReadableValue(
                                "water_supply",
                                property.water_supply
                              )}
                            </h6>
                            <p class="card-text">Water Supply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 proboxdv">
                      <div class="row g-0">
                        <div class="col-2 valign">
                          <i class="fas fa-balance-scale fa-2x"></i>
                        </div>
                        <div class="col-10">
                          <div class="card-body">
                            <h6 className="theme_yellow">
                              {" "}
                              {getReadableValue(
                                "negotiable",
                                property.negotiable
                              )}
                            </h6>
                            <p class="card-text">Nagotiable</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 proboxdv">
                      <div class="row g-0">
                        <div class="col-2 valign">
                          <i class="fa fa-sticky-note fa-2x"></i>
                        </div>
                        <div class="col-10">
                          <div class="card-body">
                            <h6 className="theme_yellow">
                              {" "}
                              {getReadableValue(
                                "created_by",
                                property.created_by
                              )}
                            </h6>
                            <p class="card-text">Posted By</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h4 class="heading mt-5">Overview</h4>

                <div class="row g-0">
                  <div class="col-md-5">
                    <table class="table mt-2">
                      <tbody>
                        {isValidField(property.residential_type) && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fas fa-city"></i> Residential Type
                              </span>
                            </td>
                            <th className="text-right ">
                              {" "}
                              {getReadableValue(
                                "residential_type",
                                property.residential_type
                              )}
                            </th>
                          </tr>
                        )}
                        {isValidField(property.commertial_type) && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fas fa-hotel"></i> Commercial Type
                              </span>
                            </td>
                            <th className="text-right">
                              {" "}
                              {getReadableValue(
                                "commertial_type",
                                property.commertial_type
                              )}
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <table class="table mt-2">
                      <tbody>
                        {isValidField(property.bhk_type) && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fa fa-bed"></i> BHK Type
                              </span>
                            </td>
                            <th className="text-right">
                              {" "}
                              {getReadableValue("bhk_type", property.bhk_type)}
                            </th>
                          </tr>
                        )}
                        {isValidField(property.floor_no) && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fas fa-stream"></i> Floor No.
                              </span>
                            </td>
                            <th className="text-right">{property.floor_no}</th>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <table class="table mt-2">
                      <tbody>
                        {isValidField(property.remarks_on_furnishing) && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fa fa-pencil-square"></i> Remark
                                Furnishing
                              </span>
                            </td>
                            <th className="text-right">
                              {property.remarks_on_furnishing}
                            </th>
                          </tr>
                        )}
                        {isValidField(property.prefered_tenants) && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fa fa-neuter"></i> Preferred Tenant
                              </span>
                            </td>
                            <th className="text-right">
                              {" "}
                              {getReadableValue(
                                "prefered_tenants",
                                property.prefered_tenants
                              )}
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <table class="table mt-2">
                      <tbody>
                        {isValidField(property.preferred_business) && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fa fa-briefcase"></i> Preferred
                                Business
                              </span>
                            </td>
                            <th className="text-right">
                              {" "}
                              {getReadableValue(
                                "preferred_business",
                                property.preferred_business
                              )}
                            </th>
                          </tr>
                        )}
                        {isValidField(property.municipal_authority) && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fas fa-industry"></i> Muncipal
                                Authority
                              </span>
                            </td>
                            <th className="text-right">
                              {property.municipal_authority}
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    <table class="table mt-2">
                      <tbody>
                        {isValidField(property.available_visiting_days) && (
                          <tr>
                            <td>
                              <span className="meta">
                                <i className="fa fa-calendar-check"></i>{" "}
                                Available Visiting Days
                              </span>
                            </td>
                            <th className="text-right">
                              {getReadableDays(
                                property.available_visiting_days
                              )}
                            </th>
                          </tr>
                        )}
                        {property.available_from && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fa fa-calendar "></i> Available From
                              </span>
                            </td>
                            <th className="text-right">
                              {new Date(
                                property.available_from
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </th>
                          </tr>
                        )}
                        {isValidField(property.visitor_parking_type) && (
                          <tr>
                            <td>
                              <span class="meta">
                                <i class="fa fa-tree"></i> Visitor Parking Type
                              </span>
                            </td>
                            <th className="text-right">
                              {" "}
                              {getReadableValue(
                                "visitor_parking_type",
                                property.visitor_parking_type
                              )}
                            </th>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="mt-5">
                <div className="d-flex justify-content-between mb-2">
                  <h5>Other Details</h5>
                  <button
                    onClick={handleToggleOwnerDetails}
                    className="btn"
                    style={{
                      // marginRight: "10rem",
                      background: "#feb406",
                      color: "white",
                    }}
                  >
                    {showOwnerDetails
                      ? "Hide Owner Details"
                      : "Get Owner Details"}
                  </button>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {property.amenities ? (
                    <ul style={{width:'57%'}}>
                      <strong className="theme_yellow">Amenities</strong>
                      {property.amenities.split(",").map((amenity, index) => (
                        <li key={index}>
                          <i
                            className="fa fa-check theme_yellow"
                            aria-hidden="true"
                          ></i>{" "}
                          {amenity}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="theme_yellow">No Amenities</p>
                  )}

                  {showOwnerDetails && ownerDetails && (
                    <div className="mt-3" style={{ }}>
                      <h6 className="theme_yellow">Owner Details</h6>
                      <p>
                        <strong>
                          <i
                            className="fa fa-check theme_yellow"
                            aria-hidden="true"
                          ></i>{" "}
                          Name:
                        </strong>{" "}
                        {ownerDetails.name}
                      </p>
                      <p>
                        <strong>
                          <i
                            className="fa fa-check theme_yellow"
                            aria-hidden="true"
                          ></i>{" "}
                          Email:
                        </strong>{" "}
                        {ownerDetails.email}
                      </p>
                      <p>
                        <strong>
                          <i
                            className="fa fa-check theme_yellow"
                            aria-hidden="true"
                          ></i>{" "}
                          Mobile:
                        </strong>{" "}
                        {ownerDetails.mobile}
                      </p>
                    </div>
                  )}
                  {error && (
                    <div className="alert alert-danger mt-3">{error}</div>
                  )}
                </div>
              </div>

              <div>
                <div class="row justify-content-between mt-4">
                  <div class="col-lg-7 ">
                    <h3>Property Description</h3>
                    <p>{property.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>No property details available</p>
          )}
        </div>
      </div>
      {!userId && (
        <LoginModal show={loginModalShow} handleClose={handleClose} />
      )}
      <Footer />
    </>
  );
};

export default PropertyDetails;
