import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { BaseUrl } from "../BaseUrl";
import { useNavigate } from "react-router-dom";
import Toast, { showToast } from "../Toast.jsx";

const ForgotPassword = () => {
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BaseUrl}/enteremail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.message==="OTP sent to your email") {
        // console.log(data.message);
        setSuccessMessage(data.message);
        setShowOTPForm(true);
      } else {
        // setError(data.message);
        showToast.danger(data.message)
      }
    } catch (error) {
      setError(error.message);
      showToast.danger(error.message,"dfghjuio")
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!otp.trim()) {
        setError("Please enter the OTP.");
        return;
      }
      const response = await fetch(`${BaseUrl}/enterotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      if (data.message==="OTP verified successfully") {
        setSuccessMessage(data.message);
        setShowPasswordForm(true);
      } else {
        setSuccessMessage(data.message);
        setError(data.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BaseUrl}/enternewpassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, newPassword: password }),
      });
      const data = await response.json();
      if (data.message==="Password reset successfully") {
        setSuccessMessage(data.message);
        navigate("/login");
        // Redirect user to login page or show a success message
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <Header />
      <div className="section">
        <div className="container mt120">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              {/* Email form */}
              {!showOTPForm && (
                <form onSubmit={handleEmailSubmit} autoComplete="off">
                  <h4 className="font-weight-bold text-primary heading mb-2">
                    Forgot Your Password?
                  </h4>
                  <p className="mb-5">
                    We get it, stuff happens. Just enter your email address
                    below and we'll send you a link to reset your password!
                  </p>
                  <h6 className="hd">Enter Email to Continue</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="mt-1 fnth2">
                    {/* <small>
                      {" "}
                      <input
                        type="checkbox"
                        className="form-check-input mt-1"
                      />{" "}
                      Get Updates on WhatsApp
                    </small> */}
                  </div>
                  <button type="submit" className="btn btn-primary col-4" style={{marginTop:"20px"}}>
                    Generate OTP
                  </button>
                </form>
              )}

              {/* OTP form */}
              {showOTPForm && !showPasswordForm && (
                <form onSubmit={handleOTPSubmit}>
                  <div className="mb-3">
                    <span>Enter OTP here</span>
                    <div className="col-12 mb-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="enter otp"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        autoComplete="off"
                        value={otp}
                        onChange={(e) => {
                          // Restrict the length of OTP to 4 characters
                          if (e.target.value.length <= 6) {
                            setOtp(e.target.value);
                            setError(null); // Clear any previous error
                          }
                        }}
                      />
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary col-4">
                    Verify OTP
                  </button>
                </form>
              )}

              {/* New password form */}
              {showPasswordForm && (
                <form onSubmit={handlePasswordSubmit}>
                  <h6 className="hd">Enter Password</h6>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter Password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button type="submit" className="btn btn-primary col-4" style={{marginTop:'10px'}}>
                    Set New Password
                  </button>
                </form>
              )}

              {/* Success message */}
              {successMessage && <p className="text-success">{successMessage}</p>}

              {/* Error message */}
              {error && <p className="text-danger">{error.message}</p>}
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
