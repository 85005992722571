import React, { useState, useEffect } from "react";
import occupations from "./occupations";
import Toast, { showToast } from "../Toast.jsx";

const UserForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    mobile1: "",
    occupation: "",
    loan: "",
    insurance: "",
    looking_for: "",
    userIP: "",
  });
  const [errors, setErrors] = useState({
    mobile: "",
    mobile1: "",
  });

  useEffect(() => {
    // Fetch user's IP address when the component mounts
    const fetchUserIP = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setFormData((prevData) => ({ ...prevData, userIP: data.ip }));
      } catch (error) {
        console.error("Error fetching user's IP address:", error);
      }
    };

    fetchUserIP();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate mobile numbers
    if (name === "mobile" || name === "mobile1") {
      const mobilePattern = /^\d{10}$/;
      if (!mobilePattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid mobile number. It should be 10 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if there are any validation errors
    if (!errors.mobile && !errors.mobile1) {
      onSubmit(formData);
    } else {
      showToast.danger("Please correct the errors in the form before submitting.");
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "transparent",
        // background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          background: "rgba(255, 255, 255, 0.1)",
          backdropFilter: "blur(10px)",
          padding: "20px",
          borderRadius: "10px",
          // width: "25%",
          maxWidth: "600px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          position: "relative",
        }}
      >
        <h5 style={{ textAlign: "center" }}>
          Fill Form to See Property Details
        </h5>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "15px" }}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter Name"
              value={formData.name}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "4px", boxSizing: "border-box" }}
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "4px", boxSizing: "border-box" }}
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              placeholder="Enter Mobile Number"
              value={formData.mobile}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "4px", boxSizing: "border-box" }}
            />
            {errors.mobile && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.mobile}
              </span>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <input
              type="tel"
              id="mobile1"
              name="mobile1"
              placeholder="Enter Alternate Mobile Number"
              value={formData.mobile1}
              onChange={handleChange}
              style={{ width: "100%", padding: "4px", boxSizing: "border-box" }}
            />
            {errors.mobile1 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors.mobile1}
              </span>
            )}
          </div>

          <div style={{ marginBottom: "15px" }}>
            <select
              id="occupation"
              name="occupation"
              onChange={handleChange}
              value={formData.occupation}
              required
              style={{ width: "100%", padding: "4px", boxSizing: "border-box" }}
            >
              <option value="" disabled>
                Select Occupation
              </option>
              {occupations.map((occupation, index) => (
                <option key={index} value={occupation}>
                  {occupation}
                </option>
              ))}
            </select>
          </div>

          <div style={{ marginBottom: "15px" }}>
            <select
              id="looking_loan"
              name="loan"
              value={formData.loan}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "4px", boxSizing: "border-box" }}
            >
              <option value="" disabled>
                Are You Looking for Loan
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div style={{ marginBottom: "15px" }}>
            <select
              id="insurance"
              name="insurance"
              value={formData.insurance}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "4px", boxSizing: "border-box" }}
            >
              <option value="" disabled>
                Do You Have Insurance
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div style={{ marginBottom: "15px" }}>
            <select
              id="looking_for"
              name="looking_for"
              value={formData.looking_for}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "4px", boxSizing: "border-box" }}
            >
              <option value="" disabled>
                Looking For
              </option>
              <option value="Rent">Rent</option>
              <option value="Buy">Buy</option>
              <option value="Lease">Lease</option>
            </select>
          </div>

          <button
            type="submit"
            style={{
              display: "block",
              width: "100%",
              padding: "10px",
              backgroundColor: "#1a1a1a",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserForm;
