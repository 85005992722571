import React from "react";
import Toast, { showToast } from "./Toast.jsx";

function FindHome() {
  return (
    <div className="section">
      <div className="container">
        <div className="row justify-content-center text-center mb-5 text-white">
          <div className="col-lg-12">
            <h2 className="font-weight-bold heading text-primary mb-4">
              Find Your Perfect Home Today
            </h2>
            <p className="text-white">
              Discover the home that matches your lifestyle and dreams. With our
              expert guidance, you'll find a place that's not just a house, but
              a home space that resonates with comfort, style, and everything
              you’ve been searching for.
            </p>
          </div>
        </div>
        <div className="row justify-content-between mb-5">
          <div className="col-lg-7 mb-5 mb-lg-0 order-lg-2">
            <div className="img-about dots mb-5">
              <img
                src="images/hero_bg_3.jpg"
                alt="Image"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-4 mt-5">
            <div className="d-flex feature-h">
              <span className="wrap-icon me-3">
                <span className="icon-home2"></span>
              </span>
              <div className="feature-text">
                <h3 className="heading">2K+ Properties</h3>
                <p>
                  Explore a vast selection of over 2K+ properties, each
                  carefully curated to help you find your perfect match.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <span className="wrap-icon me-3">
                <span className="icon-person"></span>
              </span>
              <div className="feature-text">
                <h3 className="heading">Top-Rated Agents</h3>
                <p>
                  Connect with our top-rated agents, dedicated to guiding you
                  through every step of your home journey with expert advice.
                </p>
              </div>
            </div>

            <div className="d-flex feature-h">
              <span className="wrap-icon me-3">
                <span className="icon-security"></span>
              </span>
              <div className="feature-text">
                <h3 className="heading">Trusted & Verified</h3>
                <p>
                  Rest easy knowing that all our listings are verified and
                  legit, ensuring you find a home you can trust.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row section-counter mt-5 box-shadow-web mt-50 "
          style={{
            textAlign: "center",
            //  width:'99%',
            margin: "auto",
            padding: "20px",
            boxShadow:
              "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
          }}
        >
          <div className="col-6 col-sm-6 col-md-6 col-lg-3">
            <div className="counter-wrap mb-5 mb-lg-0">
              <span className="number">
                <span className="countup text-primary">1011</span>
              </span>
              <span className="caption"># of Buy Properties</span>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-3">
            <div className="counter-wrap mb-5 mb-lg-0">
              <span className="number">
                <span className="countup text-primary">2003</span>
              </span>
              <span className="caption"># of Sell Properties</span>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-3">
            <div className="counter-wrap mb-5 mb-lg-0">
              <span className="number">
                <span className="countup text-primary">1411</span>
              </span>
              <span className="caption "># of Rent Properties</span>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-3">
            <div className="counter-wrap mb-5 mb-lg-0">
              <span className="number">
                <span className="countup text-primary">1999</span>
              </span>
              <span className="caption"># of Lease Properties</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindHome;
