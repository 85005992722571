import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Header from "./Header";
import Footer from "./Footer";
import { BaseUrl } from "./BaseUrl";
import Toast, { showToast } from "./Toast.jsx";

function InterestedYourProperties() {
  const [interestedUsers, setInterestedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    axios.get(`${BaseUrl}/interestedusersinyourproperty?userId=${userId}`)
      .then((response) => {
        if (response.data && response.data.interestedUsers) {
          setInterestedUsers(response.data.interestedUsers);
        } else {
          console.error("No interested users found in response:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching interested users:", error);
      });
  }, []);

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = interestedUsers.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <>
      <Header />
      <div className="section content">
        <div className="container mt120">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold text-primary heading">
                Interested in your Properties
              </h4>
            </div>
            <div className="col-lg-6 text-lg-end"></div>
          </div>

          <div className="row mb-5">
          <div class="col-lg-3 d-none d-lg-block d-md-block">
            <ul className="list-group tabcss">
              <li className="list-group-item ">
                <Link to="/profile">Basic Profile</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/shortlisted-properties">
                  Your Shortlisted Properties
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/your-payments"> Your Payments</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/yourproperties"> Your Properties</Link>
              </li>
              <li className="list-group-item active">
                <Link to="/profile/interested-yourproperties">
                  {" "}
                  Interested in your Properties
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/rewards"> Your Rewards</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/tickets"> Your Support Tickets</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/referral"> Referral</Link>
              </li>
            </ul>
            
          </div>

          <div class="mb-4 d-md-none d-lg-none" style={{textAlign:"right"}}>
            <div class="dropdown-center">
              <button
                class="btn btn-primary btn1 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fa fa-bookmark"></i> Interested in your Properties
              </button>
              <ul class="dropdown-menu">
                <li>
                  <Link class="dropdown-item" to="/profile">
                    Basic Profile
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/shortlisted-properties">
                    Your Shortlisted Properties
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/your-payments">
                  
                    Your Payments
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/yourproperties">
                   
                  Your Properties
                  </Link>
                </li>
                {/* <li>
                  <Link class="dropdown-item" to="/profile/interested-yourproperties">
                 
                  {" "}
                  Interested in your Properties
                  </Link>
                </li> */}
                <li>
                  <Link class="dropdown-item" to="/profile/rewards">
                  Your Rewards
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/tickets">
                  Your Support Tickets
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/referral">
                  Referral
                  </Link>
                </li>
              </ul>
            </div>
          </div>
            <div className="col-lg-9" >
              <h6 className="font-weight-bold text-primary mb-3">Interested in your Properties</h6>
              <div style={{ overflowX: "auto" }}>
              <table className="table" >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col"> Date</th>
                    <th scope="col">Customer name</th>
                    <th scope="col">Title</th>
                    <th style={{ width: "45%" }}> Description</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((user, index) => (
                    <tr key={index}>
                      <th scope="row">{indexOfFirstItem + index + 1}</th>
                      <td>{formatDate(user.created_date)}</td>
                      <td>{user.name}</td>
                      <td>{user.propertyTitle}</td>
                      <td>{user.propertyDescription}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
              <div className="" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                <button 
                  className="btn btn-primary" 
                  onClick={handlePreviousPage} 
                  disabled={currentPage === 1}
                  style={{marginRight:"10px"}}
                >
                  Previous
                </button>
                <button 
                  className="btn btn-primary" 
                  onClick={handleNextPage} 
                  disabled={indexOfLastItem >= interestedUsers.length}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InterestedYourProperties;
