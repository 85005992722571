import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
import logo from "./logo-pm.jpg";
import { BaseUrl } from "./BaseUrl";
import axios from "axios";
import { FaCaretDown } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { clearUserDetails, setUserDetails } from "../redux/actions/userActions";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Logo from "./Logo";
import Toast, { showToast } from "./Toast.jsx";

import {
  isValidMobile,
  isValidPrice,
  isValidOccupation,
} from "../utils/validations.js";
import NotificationComponent from "./NotificationComponent.js";

function Header() {
  const location = useLocation();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [activeTab, setActiveTab] = useState("home");
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userInfo, setUserInfo] = useState({
    // otp: "",
    mobile: "",
    password: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [logoError, setLogoError] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
    setActiveTab("services");
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [signupInfo, setSignupInfo] = useState({
    mobile: "",
    name: "",
    email: "",
    password: "",
    occupation: "",
    budget: "",
    gender: "",
    usertype: 0,
  });

  const handleChangeForSignup = (e) => {
    const { name, value, checked } = e.target;

    if (name === "usertype") {
      if (checked) {
        setSignupInfo({
          ...signupInfo,
          [name]: 1,
        });
      } else {
        setSignupInfo({
          ...signupInfo,
          [name]: 0,
        });
      }
    } else {
      setSignupInfo({
        ...signupInfo,
        [name]: value,
      });
    }
  };

  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  // console.log(userDetails, "userDetails from store");
  const navigate = useNavigate();

  useEffect(() => {
    // Set active tab based on the current path
    if (location.pathname === "/") {
      setActiveTab("home");
    } else if (location.pathname === "/properties") {
      setActiveTab("properties");
    } else if (location.pathname === "/legalservices") {
      setActiveTab("services");
    } else if (location.pathname === "/contact") {
      setActiveTab("contact");
    } else if (location.pathname === "/about") {
      setActiveTab("about");
    } else if (location.pathname === "/addproperties") {
      setActiveTab("addproperties");
    }
  }, [location.pathname]);

  useEffect(() => {
    const decodeStaffToken = () => {
      const token = localStorage.getItem("usertoken");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken && decodedToken._id) {
            dispatch(setUserDetails({ id: decodedToken._id }));
          }
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    };
    decodeStaffToken();
  }, [dispatch]);

  useEffect(() => {
    const getUserName = async () => {
      if (userDetails?.id) {
        try {
          const response = await axios.get(
            `${BaseUrl}/getUserNameInHeader/${userDetails.id}`
          );
          if (
            response.status === 200 &&
            response.data.message === "User details found successfully"
          ) {
            // console.log(
            //   response.data.users,
            //   "response.data.users from getUserName function"
            // );
            // name: "", mobile: "", email:"", usertype:"",occupation:"", budget:"", gender:"",city:"",state:"",country:""
            dispatch(
              setUserDetails({
                name: response.data.users.name,
                mobile: response.data.users.mobile,
                email: response.data.users.email,
                usertype: response.data.users.usertype,
                occupation: response.data.users.occupation,
                budget: response.data.users.budget,
                gender: response.data.users.gender,
                city: response.data.users.city,
                state: response.data.users.state,
                country: response.data.users.country,
                emailVerified: response.data.users.emailVerified,
              })
            );
          } else {
            console.error(
              "Error fetching user details:",
              response.data.message
            );
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    getUserName();
  }, [userDetails?.id, dispatch]);

  const handleChangeForLogin = async (e) => {
    const { name, value } = e.target;
    // if (name === "otp") {
    //   const response = await axios.get(`${BaseUrl}/generateotp`, {
    //     otp,
    //   });
    // }
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleOTP = async (e) => {
    e.preventDefault();
    try {
    } catch (error) {}
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      // if (!isValidMobile(userInfo.mobile)) {
      //   alert("please enter 10 digit valid mobile number");
      // }
      const response = await axios.post(`${BaseUrl}/userlogin`, userInfo);
      // console.log(response.data, "response in frontend");
      if (response.status === 201) {
        const { usertoken, id, email } = response.data;
        if (!localStorage.getItem("email")) {
          localStorage.setItem("email", email);
        }
        localStorage.setItem("usertoken", usertoken);
        localStorage.setItem("userId", id);

        const decodedToken = jwtDecode(usertoken);
        // name: "", mobile: "", email:"", usertype:"",occupation:"", budget:"", gender:"",city:"",state:"",country:""

        dispatch(
          setUserDetails({
            id: decodedToken._id,
            name: decodedToken.name,
            email: decodedToken.email,
            mobile: decodedToken.mobile,
            usertype: decodedToken.usertype,
            occupation: decodedToken.occupation,
            budget: decodedToken.budget,
            gender: decodedToken.gender,
            city: decodedToken.city,
            state: decodedToken.state,
            country: decodedToken.country,
            // emailVerified:response.data.users.emailVerified,
          })
        );
        navigate("/");
        // console.log(userDetails, "from header");
        setUserInfo({
          // otp: "",
          mobile: "",
          password: "",
        });
        setShowLoginModal(false);
        // alert("User login successful!");
        showToast.success("User login successful!");
      } else {
        showToast.danger(response.data.message);
        setUserInfo({
          // otp: "",
          mobile: "",
          password: "",
        });
        setShowLoginModal(false);
      }
    } catch (error) {
      // console.log(error);
      showToast.danger("User login failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const isValidEmail = (email) => {
    // Improved regex to ensure there is a valid domain suffix
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      // console.log(signupInfo, "signupinfos");

      if (!isValidMobile(signupInfo.mobile)) {
        showToast.warning("Please enter a valid 10-digit mobile number");
        return;
      }

      if (!isValidEmail(signupInfo.email)) {
        // console.log("entered inside isValidEmail");
        showToast.warning("Please enter a valid email address");
        return;
      }

      if (!isValidPrice(signupInfo.budget)) {
        showToast.warning("Please enter a valid budget greater than 5000");
        return;
      }

      if (!isValidOccupation(signupInfo.occupation)) {
        showToast.warning("Please Select an occupation");
        return;
      }

      const response = await axios.post(`${BaseUrl}/usersignup`, signupInfo);
      // console.log(response, "response at frontend");
      showToast.success(response.data.message);
      if (
        response.status == 201 &&
        response.data.message == "User registered successfully"
      ) {
        setSignupInfo({
          mobile: "",
          name: "",
          email: "",
          password: "",
          occupation: "",
          budget: "",
          gender: "",
          usertype: "",
        });
        setShowSignupModal(false);
        navigate("/");
      }
    } catch (error) {}
  };

  const handleSignOut = () => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    // localStorage.removeItem("detailstoken");
    dispatch(clearUserDetails());
    navigate("/");
  };

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/getlogodetails`);
        if (response.data.success) {
          // console.log("Logo URL fetched:", response.data.results.logo);
          setLogoUrl(response.data.results.logo);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchLogo();
  }, []);

  const handleWalletClick = () => {
    // Check if the user is logged in
    const userId = localStorage.getItem("userId");
    if (userId) {
      // If user is logged in, redirect to /profile/rewards
      window.location.href = "/profile/rewards";
    } else {
      // If user is not logged in, redirect to /login page
      // window.location.href = '/login';
      showToast.warning("Login to Show Your Rewards");
    }
  };

  const [services, setServices] = useState([]);

  const handlePostPropertyClick = () => {
    const usertoken = localStorage.getItem("usertoken");
    if (usertoken) {
      setActiveTab("addproperties");
      navigate("/addproperties");
    } else {
      navigate("/userlogin");
    }
  };

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetchServices(); // Fetch services data when component mounts
  }, []);

  const fetchServices = async () => {
    try {
      // const response = await fetch(`${BaseUrl}/getallservices`);
      const response = await fetch(`${BaseUrl}/getallcategoriesinclientside`);

      if (!response.ok) {
        throw new Error("Failed to fetch services");
      }

      const data = await response.json();
      // console.log("Fetched data:", data);
      // console.log("Fetched results:", data.results);
      setResults(data.results); // Assuming data.results contains the array of services
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handleItemClick = (id) => {
    // const formattedName = name.replace(/\s+/g, "-");

    navigate(`/legalservicepage/${encodeURIComponent(id)}`);
  };
  // const handleItemClick = (name) => {

  //   const formattedName = name.replace(/\s+/g, "-");

  //   navigate(`/legalservicepage/${encodeURIComponent(formattedName)}`);
  // };
  return (
    <div className="site-header">
      <div className="site-mobile-menu site-navbar-target ">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close">
            <span className="icofont-close js-menu-toggle" />
          </div>
        </div>
        <div className="site-mobile-menu-body" />
      </div>
      <nav className="site-nav">
        <div className="container">
          <div className="menu-bg-wrap">
            <div
              className="site-navigation"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <a href="/" style={{ marginRight: "20px" }}>
                {logoUrl && !logoError ? (
                  <img
                    src={logoUrl}
                    alt="Property Icon"
                    onLoad={() => console.log("Logo loaded successfully")}
                    onError={(e) => {
                      console.error("Error loading logo:", e);
                      setLogoError(true);
                    }}
                    style={{
                      maxWidth: "120px",
                      maxHeight: "50px",
                      objectFit: "contain",
                      marginRight: "8rem",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100px",
                      height: "50px",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "white",
                      marginRight: "10rem",
                    }}
                  >
                    HSAS
                  </div>
                )}
              </a>

              <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu float-end">
                <li className={activeTab === "home" ? "active" : ""}>
                  <Link to="/" onClick={() => setActiveTab("home")}>
                    Home
                  </Link>
                </li>
                <li
                  className={`has-children ${
                    activeTab === "services" ? "active" : ""
                  }`}
                >
                  <Link
                    to="/legalservices"
                    onClick={() => setActiveTab("services")}
                  >
                    Services
                  </Link>
                  {/* <div className="container"> */}
                  <div className="row dropdown hrop">
                    {/* Single column */}
                    <div className="col-6">
                      {results.slice(0, 8).map((result) => (
                        <ul
                          key={result.id}
                          className="list-unstyled"
                          onClick={() => handleItemClick(result.id)}
                          // onClick={() => handleItemClick(result.name)}
                          style={{
                            padding: "5px 0",
                            cursor: "pointer",
                            fontSize: "14px",
                            fontFamily: "Arial, sans-serif",
                            fontWeight: "normal",
                            minWidth: "160px",
                            // borderRadius:'10px'
                          }}
                        >
                          <li>
                            <b>{result.name}</b>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>

                  {/* </div> */}
                </li>
                <li className={activeTab === "about" ? "active" : ""}>
                  <Link to="/about" onClick={() => setActiveTab("about")}>
                    About
                  </Link>
                </li>
                {/* <li>
                  <Link to="/about">About</Link>
                </li> */}
                <li className={activeTab === "contact" ? "active" : ""}>
                  <Link to="/contact" onClick={() => setActiveTab("contact")}>
                    Contact Us
                  </Link>
                </li>
                <li className={activeTab === "addproperties" ? "active" : ""}>
                  <button
                    className="postbtn btn"
                    onClick={handlePostPropertyClick}
                  >
                    Post Your Property
                  </button>
                </li>

                <li
                  className="wallet"
                  style={{ color: "Yellow" }}
                  onClick={handleWalletClick}
                >
                  <i
                    className="fas fa-coins"
                    style={{
                      marginRight: "8px",
                      cursor: "pointer",
                      fontSize: "24px",
                    }}
                  ></i>
                </li>

                {userDetails?.name ? (
                  <li className="nav-item dropdown">
                    <a>
                      <NotificationComponent userId={userId} />
                    </a>
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i
                        className="fa fa-user"
                        style={{ marginRight: "5px" }}
                      ></i>{" "}
                      {userDetails.name}
                    </a>
                    <ul className="dropdown-menu">
                      <li className="active">
                        <Link className="dropdown-item" to="/profile">
                          Profile
                        </Link>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/profile/tickets">
                          Raise Ticket
                        </a>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/profile/yourproperties"
                        >
                          My Listings
                        </Link>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/legalservices">
                          Service
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={handleSignOut}
                          className="dropdown-item"
                          href="#"
                        >
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <>
                    <li>
                      <a
                        className="loginbtn"
                        href="#"
                        onClick={() => setShowLoginModal(true)}
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <a href="/signup" className="loginbtn">
                        SignUp
                      </a>
                    </li>
                  </>
                )}
              </ul>

              <div style={{ display: isOpen ? "block" : "none" }}>
                <nav
                  id="main-navbar"
                  className={`collapse ${isOpen ? "show" : ""}`}
                  style={{
                    position: "fixed",
                    top: "0",
                    right: "0",
                    width: "80%",
                    height: "100%",
                    backgroundColor: "#000",
                    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                    zIndex: "1",
                    padding: "20px",
                    boxSizing: "border-box",
                    transition: "transform 0.3s ease",
                    transform: isOpen ? "translateX(0)" : "translateX(100%)",
                  }}
                >
                  <button
                    onClick={toggleMenu}
                    style={{ float: "right", marginBottom: "50px" }}
                  >
                    X
                  </button>
                  <ul
                    className="nav__list"
                    style={{
                      listStyle: "none",
                      padding: "0",
                      marginTop: "50px",
                      marginLeft: "50px",
                      textAlign: "left",
                    }}
                  >
                    {userDetails?.name ? (
                      <li className="nav__item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa fa-user"></i> {userDetails.name}
                        </a>
                        <ul
                          className="dropdown-menu"
                          style={{
                            background: "#000",
                            border: "1px solid #fff",
                          }}
                        >
                          <li className="active">
                            <Link
                              className="dropdown-item text-white"
                              to="/profile"
                            >
                              Profile
                            </Link>
                          </li>
                          <li>
                            <a
                              className="dropdown-item text-white"
                              href="/profile/tickets"
                            >
                              Raise Ticket
                            </a>
                          </li>
                          {/* Additional user-specific dropdown items */}
                          <li>
                            <Link
                              className="dropdown-item text-white"
                              to="/profile/yourproperties"
                            >
                              My Listings
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item text-white"
                              to="/legalservices"
                            >
                              Service
                            </Link>
                          </li>
                          <li>
                            <a
                              onClick={handleSignOut}
                              className="dropdown-item text-white"
                              href="#"
                            >
                              Sign Out
                            </a>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      <>
                        <li>
                          <button
                            className="loginbtn"
                            href="#"
                            onClick={() => setShowLoginModal(true)}
                            style={{
                              textDecoration: "none",
                              color: "#fff",
                              fontSize: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            Login
                          </button>
                        </li>
                        <li>
                          <a
                            href="/signup"
                            className="loginbtn"
                            style={{
                              textDecoration: "none",
                              color: "#fff",
                              fontSize: "20px",
                            }}
                          >
                            SignUp
                          </a>
                        </li>
                      </>
                    )}
                    <li className="nav__item" style={{ margin: "20px 0" }}>
                      <a
                        href="/"
                        className="nav__link"
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                          fontSize: "20px",
                        }}
                      >
                        Home
                      </a>
                    </li>

                    <li
                      className="nav__item"
                      style={{ margin: "20px 0", position: "relative" }}
                    >
                      <a
                        href="/legalservices"
                        className="nav__link"
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                          fontSize: "20px",
                        }}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the default link behavior
                          handleToggleDropdown();
                        }}
                      >
                        Services <FaCaretDown style={{ marginLeft: "5px" }} />
                      </a>
                      {showDropdown && activeTab === "services" && (
                        <div
                          className="row dropdown"
                          style={{
                            // position: "absolute",
                            top: "100%",
                            left: "0",
                            backgroundColor: "#000",
                            // zIndex: "1000",
                            minWidth: "200px",
                            width:'fit-content',
                            border:'1px solid #fff',
                            borderRadius:'7px'
                          }}
                        >
                          {/* Single column */}
                          <div className="col-6">
                            {results.slice(0, 8).map((result) => (
                              <ul
                                key={result.id}
                                className="list-unstyled"
                                onClick={() => handleItemClick(result.id)}
                                style={{
                                  // padding: "5px 0",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                  fontFamily: "Arial, sans-serif",
                                  fontWeight: "normal",
                                  minWidth: "200px",
                                  backgroundColor: "#000",
                                  padding:'5px'
                                }}
                              >
                                <li className="dropdown-item">
                                  <b >{result.name}</b>
                                </li>
                              </ul>
                            ))}
                          </div>
                        </div>
                      )}
                    </li>

                    <li className="nav__item" style={{ margin: "20px 0" }}>
                      <a
                        href="/about"
                        className="nav__link"
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                          fontSize: "20px",
                        }}
                      >
                        About
                      </a>
                    </li>
                    <li className="nav__item" style={{ margin: "20px 0" }}>
                      <a
                        href="/contact"
                        className="nav__link"
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                          fontSize: "20px",
                        }}
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <button
                        className="postbtn btn"
                        onClick={handlePostPropertyClick}
                      >
                        Post Your Property
                      </button>
                    </li>

                    {/* Conditional rendering based on user login status */}
                  </ul>
                </nav>
              </div>

              {/* Login Modal */}
              {showLoginModal && (
                <div className="modal show" style={{ display: "block" }}>
                  <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable loginmdl">
                    <div
                      className="modal-content"
                      style={{ border: "1px solid white" }}
                    >
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close text-white"
                          style={{ color: "white !important" }}
                          onClick={() => setShowLoginModal(false)}
                        />
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div
                            className="col-lg-5 mb-5 mb-lg-0"
                            data-aos-delay={100}
                          >
                            <div className="text-center mt-4">
                              {logoUrl && !logoError ? (
                                <img
                                  src={logoUrl}
                                  alt="Property Icon"
                                  onLoad={() =>
                                    console.log("Logo loaded successfully")
                                  }
                                  onError={(e) => {
                                    console.error("Error loading logo:", e);
                                    setLogoError(true);
                                  }}
                                  style={{
                                    maxWidth: "40%",
                                    maxHeight: "50%",
                                    objectFit: "contain",
                                  }}
                                />
                              ) : (
                                {
                                  /* <div>HSAS</div> */
                                }
                              )}
                            </div>
                            <div className="mt-4">
                              <h6 className="hd text-white">
                                Login up For Users
                              </h6>
                              <ul className="checklist">
                                <li title="Zero Brokerage.">Zero Brokerage.</li>
                                <li title="Thousands of new listings daily.">
                                  Thousands of new listings daily.
                                </li>
                                <li title="100 Cr+ Brokerage saved monthly.">
                                  100 Cr+ Brokerage saved monthly.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-7" data-aos-delay={200}>
                            <div className="mdlcnt">
                              <h6 className="hd text-white">
                                Enter Phone/ Email To Continue
                              </h6>
                              <form onSubmit={handleLogin}>
                                <div className="row mt-2">
                                  <div className="col-12 mb-3">
                                    <input
                                      type="text"
                                      name="mobile"
                                      className="form-control"
                                      placeholder="Enter Your Phone / Email"
                                      onChange={handleChangeForLogin}
                                      value={userInfo.mobile}
                                    />
                                    {/* <div className="mt-1 fnth2">
                                      <small>
                                        <input
                                          type="checkbox"
                                          className="form-check-input mt-1"
                                        />
                                        Get Updates on WhatsApp
                                      </small>
                                    </div> */}
                                  </div>
                                  {/* <div className="col-12 mb-3">
                                     <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="loginOption"
                                        value="otp"
                                        onChange={() =>
                                          setSelectedOption("otp")
                                        }
                                      />
                                      <label className="form-check-label">
                                        Generate OTP
                                      </label>
                                    </div> 
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="loginOption"
                                        value="password"
                                        onChange={() =>
                                          setSelectedOption("password")
                                        }
                                      />
                                      <label className="form-check-label">
                                        I have Password
                                      </label>
                                    </div>
                                  </div> */}
                                  {/* {selectedOption === "otp" && (
                                    <div className="mb-3">
                                      <span>Enter OTP here</span>
                                      <div className="col-12 otpdiv">
                                        <input
                                          className="form-control"
                                          type="text"
                                          inputMode="numeric"
                                          maxLength={10}
                                          autoComplete="off"
                                        />
                                      </div>
                                      <div className="text-right fnth1">
                                        Resend OTP
                                      </div>
                                    </div>
                                  )} */}
                                  {/* {selectedOption === "password" && ( */}
                                  <div className="col-12 mb-3">
                                    <input
                                      type="password"
                                      className="form-control"
                                      name="password"
                                      placeholder="Enter Password"
                                      onChange={handleChangeForLogin}
                                      value={userInfo.password}
                                    />
                                  </div>
                                  {/* )} */}
                                  <div className="col-12">
                                    <input
                                      type="submit"
                                      value="Login"
                                      className="btn btn-primary col-12"
                                      disabled={isSubmitting}
                                    />
                                  </div>
                                  <div className="col-lg mt-3">
                                    <a
                                      className="text-right fnth1 text-white"
                                      href="/forgotpassword"
                                    >
                                      Forgot password?
                                    </a>
                                  </div>
                                  <div className="mt-3 text-center">
                                    <small>
                                      By continuing, you agree to our
                                     <Link to="/terms-condition"> <strong>Terms &amp; Conditions</strong></Link>
                                    </small>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Signup Modal */}
              {showSignupModal && (
                <div className="modal show" style={{ display: "block" }}>
                  <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable loginmdl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowSignupModal(false)}
                        />
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div
                            className="col-lg-5 mb-5 mb-lg-0"
                            data-aos-delay={100}
                          >
                            <div className="text-center mt-4">
                              <img src={logo} alt="property icon" />
                            </div>
                            <div className="mt-4">
                              <h6 className="hd">Sign up For Users</h6>
                              <ul className="checklist">
                                <li title="Zero Brokerage.">Zero Brokerage.</li>
                                <li title="Thousands of new listings daily.">
                                  Thousands of new listings daily.
                                </li>
                                <li title="100 Cr+ Brokerage saved monthly.">
                                  100 Cr+ Brokerage saved monthly.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-7" data-aos-delay={200}>
                            <div className="mdlcnt">
                              <h6 className="hd">Enter Phone To Continue</h6>
                              {/* <form onSubmit={handleSignup}>
                                <div className="row  mt-2">
                                  <div className="col-12 mb-3">
                                    <input
                                      type="text"
                                      name='mobile'
                                      className="form-control"
                                      placeholder="Enter Your Mobile Number"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.mobile}
                                    />
                                    <div
                                      style={{
                                        color: "#005555",
                                        fontWeight: 600,
                                      }}
                                      className="mt-1"
                                    >
                                      <small>
                                        <input
                                          type="checkbox"
                                          className="form-check-input mt-2"
                                        />
                                        Get Updates on WhatsApp
                                      </small>
                                    </div>
                                  </div>
                                  <div className="col-12 mb-3">
                                    <input
                                      type="text"
                                      name="name"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.name}
                                    />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <input
                                      type="text"
                                      name="email"
                                      className="form-control"
                                      placeholder="Enter Your Email Like shashi@gmail.com"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.email}
                                    />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <input
                                      type="password"
                                      name="password"
                                      className="form-control"
                                      placeholder="Enter Your Password"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.password}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <input
                                      type="submit"
                                      value="Continue"
                                      className="btn btn-primary col-12"
                                    />
                                  </div>
                                  <div className="mt-3 text-center">
                                    <small>
                                      By continuing, you agree to our
                                      <strong>Terms &amp; Conditions</strong>
                                    </small>
                                  </div>
                                </div>
                              </form> */}
                              <form onSubmit={handleSignup}>
                                <div className="row mt-2">
                                  <div className="col-md-6 mb-3">
                                    <input
                                      type="text"
                                      name="mobile"
                                      className="form-control"
                                      placeholder="Enter Your Mobile Number"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.mobile}
                                    />
                                    <div
                                      style={{
                                        color: "#005555",
                                        fontWeight: 600,
                                      }}
                                      className="mt-1"
                                    >
                                      {/* <small>
                                        <input
                                          type="checkbox"
                                          className="form-check-input mt-2"
                                        />
                                        Get Updates on WhatsApp
                                      </small> */}
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <input
                                      type="text"
                                      name="name"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.name}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <input
                                      type="text"
                                      name="email"
                                      className="form-control"
                                      placeholder="Enter Your Email Like xyz@gmail.com"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.email}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <input
                                      type="password"
                                      name="password"
                                      className="form-control"
                                      placeholder="Enter Your Password"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.password}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <select
                                      className="form-control"
                                      id="occupation"
                                      name="occupation"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.occupation}
                                    >
                                      <option value="" disabled>
                                        Profession
                                      </option>
                                      <optgroup label="Healthcare Practitioners and Technical Occupations:">
                                        <option value="Chiropractor">
                                          Chiropractor
                                        </option>
                                        <option value="Dentist">Dentist</option>
                                        <option value="Dietitian or Nutritionist">
                                          Dietitian or Nutritionist
                                        </option>
                                        <option value="Optometrist">
                                          Optometrist
                                        </option>
                                        <option value="Pharmacist">
                                          Pharmacist
                                        </option>
                                        <option value="Physician">
                                          Physician
                                        </option>
                                        <option value="Physician Assistant">
                                          Physician Assistant
                                        </option>
                                        <option value="Podiatrist">
                                          Podiatrist
                                        </option>
                                        <option value="Registered Nurse">
                                          Registered Nurse
                                        </option>
                                        <option value="Therapist">
                                          Therapist
                                        </option>
                                        <option value="Veterinarian">
                                          Veterinarian
                                        </option>
                                        <option value="Health Technologist or Technician">
                                          Health Technologist or Technician
                                        </option>
                                        <option value="Other Healthcare Practitioners and Technical Occupation">
                                          Other Healthcare Practitioners and
                                          Technical Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Healthcare Support Occupations:">
                                        <option value="Nursing, Psychiatric, or Home Health Aide">
                                          Nursing, Psychiatric, or Home Health
                                          Aide
                                        </option>
                                        <option value="Occupational and Physical Therapist Assistant or Aide">
                                          Occupational and Physical Therapist
                                          Assistant or Aide
                                        </option>
                                        <option value="Other Healthcare Support Occupation">
                                          Other Healthcare Support Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Business, Executive, Management, and Financial Occupations:">
                                        <option value="Chief Executive">
                                          Chief Executive
                                        </option>
                                        <option value="General and Operations Manager">
                                          General and Operations Manager
                                        </option>
                                        <option value="Advertising, Marketing, Promotions, Public Relations, and Sales Manager">
                                          Advertising, Marketing, Promotions,
                                          Public Relations, and Sales Manager
                                        </option>
                                        <option value="Operations Specialties Manager (e.g., IT or HR Manager)">
                                          Operations Specialties Manager (e.g.,
                                          IT or HR Manager)
                                        </option>
                                        <option value="Construction Manager">
                                          Construction Manager
                                        </option>
                                        <option value="Engineering Manager">
                                          Engineering Manager
                                        </option>
                                        <option value="Accountant, Auditor">
                                          Accountant, Auditor
                                        </option>
                                        <option value="Business Operations or Financial Specialist">
                                          Business Operations or Financial
                                          Specialist
                                        </option>
                                        <option value="Business Owner">
                                          Business Owner
                                        </option>
                                        <option value="Other Business, Executive, Management, Financial Occupation">
                                          Other Business, Executive, Management,
                                          Financial Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Architecture and Engineering Occupations:">
                                        <option value="Architect, Surveyor, or Cartographer">
                                          Architect, Surveyor, or Cartographer
                                        </option>
                                        <option value="Engineer">
                                          Engineer
                                        </option>
                                        <option value="Other Architecture and Engineering Occupation">
                                          Other Architecture and Engineering
                                          Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Education, Training, and Library Occupations:">
                                        <option value="Postsecondary Teacher (e.g., College Professor)">
                                          Postsecondary Teacher (e.g., College
                                          Professor)
                                        </option>
                                        <option value="Primary, Secondary, or Special Education School Teacher">
                                          Primary, Secondary, or Special
                                          Education School Teacher
                                        </option>
                                        <option value="Other Teacher or Instructor">
                                          Other Teacher or Instructor
                                        </option>
                                        <option value="Other Education, Training, and Library Occupation">
                                          Other Education, Training, and Library
                                          Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Other Professional Occupations:">
                                        <option value="Arts, Design, Entertainment, Sports, and Media Occupations">
                                          Arts, Design, Entertainment, Sports,
                                          and Media Occupations
                                        </option>
                                        <option value="Computer Specialist, Mathematical Science">
                                          Computer Specialist, Mathematical
                                          Science
                                        </option>
                                        <option value="Counselor, Social Worker, or Other Community and Social Service Specialist">
                                          Counselor, Social Worker, or Other
                                          Community and Social Service
                                          Specialist
                                        </option>
                                        <option value="Lawyer, Judge">
                                          Lawyer, Judge
                                        </option>
                                        <option value="Life Scientist (e.g., Animal, Food, Soil, or Biological Scientist, Zoologist)">
                                          Life Scientist (e.g., Animal, Food,
                                          Soil, or Biological Scientist,
                                          Zoologist)
                                        </option>
                                        <option value="Physical Scientist (e.g., Astronomer, Physicist, Chemist, Hydrologist)">
                                          Physical Scientist (e.g., Astronomer,
                                          Physicist, Chemist, Hydrologist)
                                        </option>
                                        <option value="Religious Worker (e.g., Clergy, Director of Religious Activities or Education)">
                                          Religious Worker (e.g., Clergy,
                                          Director of Religious Activities or
                                          Education)
                                        </option>
                                        <option value="Social Scientist and Related Worker">
                                          Social Scientist and Related Worker
                                        </option>
                                        <option value="Other Professional Occupation">
                                          Other Professional Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Office and Administrative Support Occupations:">
                                        <option value="Supervisor of Administrative Support Workers">
                                          Supervisor of Administrative Support
                                          Workers
                                        </option>
                                        <option value="Financial Clerk">
                                          Financial Clerk
                                        </option>
                                        <option value="Secretary or Administrative Assistant">
                                          Secretary or Administrative Assistant
                                        </option>
                                        <option value="Material Recording, Scheduling, and Dispatching Worker">
                                          Material Recording, Scheduling, and
                                          Dispatching Worker
                                        </option>
                                        <option value="Other Office and Administrative Support Occupation">
                                          Other Office and Administrative
                                          Support Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Services Occupations:">
                                        <option value="Protective Service (e.g., Fire Fighting, Police Officer, Correctional Officer)">
                                          Protective Service (e.g., Fire
                                          Fighting, Police Officer, Correctional
                                          Officer)
                                        </option>
                                        <option value="Chef or Head Cook">
                                          Chef or Head Cook
                                        </option>
                                        <option value="Cook or Food Preparation Worker">
                                          Cook or Food Preparation Worker
                                        </option>
                                        <option value="Food and Beverage Serving Worker (e.g., Bartender, Waiter, Waitress)">
                                          Food and Beverage Serving Worker
                                          (e.g., Bartender, Waiter, Waitress)
                                        </option>
                                        <option value="Building and Grounds Cleaning and Maintenance">
                                          Building and Grounds Cleaning and
                                          Maintenance
                                        </option>
                                        <option value="Personal Care and Service (e.g., Hairdresser, Flight Attendant, Concierge)">
                                          Personal Care and Service (e.g.,
                                          Hairdresser, Flight Attendant,
                                          Concierge)
                                        </option>
                                        <option value="Sales Supervisor, Retail Sales">
                                          Sales Supervisor, Retail Sales
                                        </option>
                                        <option value="Retail Sales Worker">
                                          Retail Sales Worker
                                        </option>
                                        <option value="Insurance Sales Agent">
                                          Insurance Sales Agent
                                        </option>
                                        <option value="Sales Representative">
                                          Sales Representative
                                        </option>
                                        <option value="Real Estate Sales Agent">
                                          Real Estate Sales Agent
                                        </option>
                                        <option value="Other Services Occupation">
                                          Other Services Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Agriculture, Maintenance, Repair, and Skilled Crafts Occupations:">
                                        <option value="Construction and Extraction (e.g., Construction Laborer, Electrician)">
                                          Construction and Extraction (e.g.,
                                          Construction Laborer, Electrician)
                                        </option>
                                        <option value="Farming, Fishing, and Forestry">
                                          Farming, Fishing, and Forestry
                                        </option>
                                        <option value="Installation, Maintenance, and Repair">
                                          Installation, Maintenance, and Repair
                                        </option>
                                        <option value="Production Occupations">
                                          Production Occupations
                                        </option>
                                        <option value="Other Agriculture, Maintenance, Repair, and Skilled Crafts Occupation">
                                          Other Agriculture, Maintenance,
                                          Repair, and Skilled Crafts Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Transportation Occupations:">
                                        <option value="Aircraft Pilot or Flight Engineer">
                                          Aircraft Pilot or Flight Engineer
                                        </option>
                                        <option value="Motor Vehicle Operator (e.g., Ambulance, Bus, Taxi, or Truck Driver)">
                                          Motor Vehicle Operator (e.g.,
                                          Ambulance, Bus, Taxi, or Truck Driver)
                                        </option>
                                        <option value="Other Transportation Occupation">
                                          Other Transportation Occupation
                                        </option>
                                      </optgroup>
                                      <optgroup label="Other Occupations:">
                                        <option value="Military">
                                          Military
                                        </option>
                                        <option value="Homemaker">
                                          Homemaker
                                        </option>
                                        <option value="Other Occupation">
                                          Other Occupation
                                        </option>
                                        <option value="Don't Know">
                                          Don't Know
                                        </option>
                                        <option value="Not Applicable">
                                          Not Applicable
                                        </option>
                                      </optgroup>
                                    </select>
                                  </div>

                                  <div className="col-md-6 mb-3">
                                    <input
                                      type="text"
                                      name="budget"
                                      className="form-control"
                                      placeholder="Enter Your Budget"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.budget}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <select
                                      className="form-control"
                                      name="gender"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.gender}
                                    >
                                      <option value="" disabled>
                                        Select Gender
                                      </option>
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                      <option value="other">Other</option>
                                    </select>
                                  </div>
                                  {/* <div className="col-md-6 mb-3">
                                    <select
                                      className="form-control"
                                      name="usertype"
                                      onChange={handleChangeForSignup}
                                      value={signupInfo.usertype}
                                    >
                                      <option value="" disabled>
                                        Are you a Broker
                                      </option>
                                      <option value={1}>Yes</option>
                                      <option value={0}>No</option>
                                    </select>
                                  </div> */}
                                  <div className="col-md-6 mb-3">
                                    <label>Are you a Broker?</label>
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="usertype"
                                        onChange={handleChangeForSignup}
                                        checked={signupInfo.usertype === 1}
                                      />
                                      <label>Yes</label>
                                    </div>
                                  </div>

                                  <div className="col-12 mb-3">
                                    <input
                                      type="submit"
                                      value="Continue"
                                      className="btn btn-primary col-12"
                                    />
                                  </div>
                                  <div className="mt-3 text-center col-12">
                                    <small>
                                      By continuing, you agree to our{" "}
                                      <strong>Terms &amp; Conditions</strong>
                                    </small>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <a
                href="#"
                className="burger light me-auto float-end mt-1 site-menu-toggle js-menu-toggle d-inline-block d-lg-none"
                data-toggle="collapse"
                onClick={toggleMenu}
              >
                <span />
              </a> */}
              <a
                href="#"
                className="burger light me-auto float-end mt-1 site-menu-toggle js-menu-toggle d-inline-block d-lg-none"
                data-toggle="collapse"
                onClick={toggleMenu}
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  position: "relative",
                  display: "block",
                  width: "30px",
                  height: "30px",
                  zIndex: "0",
                  background: isOpen ? "#333" : "transparent",
                  transition: "background 0.3s ease",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: "100%",
                      height: "2px",
                      backgroundColor: isOpen ? "#fff" : "#fff",
                      borderRadius: "5px",
                      // transform: isOpen ? "rotate(45deg)" : "rotate(0)",
                      transition: "transform 0.3s ease",
                      marginBottom: "4px",
                    }}
                  ></span>
                  <span
                    style={{
                      display: "block",
                      width: "100%",
                      height: "2px",
                      backgroundColor: isOpen ? "#fff" : "#fff",
                      borderRadius: "5px",
                      // transform: isOpen ? "rotate(-45deg)" : "rotate(0)",
                      transition: "transform 0.3s ease",
                    }}
                  ></span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
