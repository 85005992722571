import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BaseUrl } from "./BaseUrl";
import { setUserDetails } from "../redux/actions/userActions";
import { browserName, osName } from "react-device-detect";
import Toast, { showToast } from "./Toast.jsx";

const MobileLoginPage = () => {
  const [userInfo, setUserInfo] = useState({
    mobile: "",
    password: "",
  });
  const [ipAddress, setIpAddress] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [logoError, setLogoError] = useState(false);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/getlogodetails`);
        if (response.data.success) {
          setLogoUrl(response.data.results.logo);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchLogo();
  }, []);

  const handleChangeForLogin = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const loginData = {
        ...userInfo,
        ip: ipAddress,
        browser: browserName,
        os: osName,
      };
      const response = await axios.post(`${BaseUrl}/userlogin`, loginData);

      if (response.status === 201) {
        const { usertoken, id, email } = response.data;
        if (!localStorage.getItem("email")) {
          localStorage.setItem("email", email);
        }
        localStorage.setItem("usertoken", usertoken);
        localStorage.setItem("userId", id);
        const decodedToken = jwtDecode(usertoken);
        dispatch(
          setUserDetails({
            id: decodedToken._id,
            name: decodedToken.name,
            email: decodedToken.email,
            mobile: decodedToken.mobile,
            usertype: decodedToken.usertype,
            occupation: decodedToken.occupation,
            budget: decodedToken.budget,
            gender: decodedToken.gender,
            city: decodedToken.city,
            state: decodedToken.state,
            country: decodedToken.country,
          })
        );
        setUserInfo({
          mobile: "",
          password: "",
        });

        // Extract the redirect path from the query parameters
        const params = new URLSearchParams(location.search);
        const redirectPath = params.get("redirect");
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          navigate("/");
        }
      } else {
        showToast.success(response.data.message);
        setUserInfo({
          mobile: "",
          password: "",
        });
      }
    } catch (error) {
      console.error(error);
      showToast.danger("An error occurred during login. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="content" style={{ marginTop: "3em", padding: "1em" }}>
      <div className="" style={{ float: "right"}}>
        <button
          type="button"
          className="btn-close"
          onClick={() => navigate(-1)}
          style={{fontSize:'23px'}}
        ></button>
      </div>
      <div className="text-center mt-4" style={{marginTop:'3rem'}}>
        {logoUrl && !logoError ? (
          <img
            src={logoUrl}
            alt="Property Icon"
            onLoad={() => console.log("Logo loaded successfully")}
            onError={(e) => {
              console.error("Error loading logo:", e);
              setLogoError(true);
            }}
            style={{
              maxWidth: "40%",
              maxHeight: "50%",
              objectFit: "contain",
            }}
          />
        ) : null}
        <div style={{display:'flex', justifyContent:'space-between', marginTop:'3rem'}}>
          <h1>Login</h1>
          <Link to="/mobilesignup" className="btn btn-primary">
            Create Account
          </Link>
        </div>
      </div>
      <div className="mt-4">
        <h6 className="hd">Login For Users</h6>
      </div>
      <form onSubmit={handleLogin} autoComplete="off">
        <div className="row mt-2">
          <div className="col-12 mb-3">
            <label>Enter Mobile Number / Email</label>
            <input
              type="text"
              name="mobile"
              className="form-control"
              placeholder="Enter Your Mobile Number / Email"
              onChange={handleChangeForLogin}
              value={userInfo.mobile}
              required
            />
          </div>
          <div className="col-12 mb-3">
            <label>Enter Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              placeholder="Enter Password"
              onChange={handleChangeForLogin}
              value={userInfo.password}
              autoComplete="new-password"
              required
            />
          </div>
          <div className="text-center" >
            <input
              type="submit"
              value="Login"
              className="btn btn-primary col-6"
              disabled={isSubmitting}
              style={{float:'right', margin:'1rem 0rem'}}
            />
          </div>
          <div style={{marginBottom:'1rem'}}>
            <Link to="/forgotpassword" >
              Forgot Password
            </Link>
          </div>
          <div className="mt-3">
            <small>
              By continuing, you agree to our{" "}
              <Link to="/terms-condition" style={{color:'#fff'}}>
              <strong>Terms &amp; Conditions</strong>
              </Link>
            </small>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MobileLoginPage;
