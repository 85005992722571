import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { BaseUrl } from './BaseUrl';
import './ImageGallery.css'; // Import custom styles if needed
import Toast, { showToast } from "./Toast.jsx";

const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false); // State to track if images are loaded

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/getgallerysectionphotos`);
        if (response.data && response.data.results) {
          setImages(response.data.results);
          setLoaded(true); // Set loaded to true once images are fetched
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <Container>
      <h3 className='mb-4'>Image Gallery</h3>
      <Row>
        {images.map((image, index) => (
          <Col key={index} xs={12} sm={6} md={6} lg={6} className="d-flex align-items-stretch">
            <Card className="mb-4 w-100" onClick={() => openLightbox(index)}>
              {/* Ensure the image is loaded before showing */}
              {loaded && (
                <Card.Img variant="top" src={image.image} alt={`Image ${index + 1}`} className="gallery-image" />
              )}
            </Card>
          </Col>
        ))}
      </Row>

      {isOpen && images.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex].image}
          nextSrc={images[(photoIndex + 1) % images.length].image}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].image}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </Container>
  );
};

export default ImageGallery;
