import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { BaseUrl } from './BaseUrl';
import { useNavigate } from 'react-router-dom';
import Toast, { showToast } from "./Toast.jsx";

const MobileNotification = ({ userId }) => {
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const pageRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect to /mobilelogin if userId is not present
        // if (!userId) {
        //     navigate('/mobilelogin');
        //     return;
        // }

        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${BaseUrl}/getnotifications/${userId}`);
                setNotifications(response.data);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();
    }, [userId, navigate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pageRef.current && !pageRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNotificationClick = () => {
        if (userId) {
            setShowNotifications(prev => !prev);
        } else {
            navigate('/mobilelogin');
        }
    };

    const handleResponse = async (notificationId, response) => {
        try {
            await axios.post(`${BaseUrl}/sendnotificationresponse/${notificationId}`, { response });
            slideOutAndRemove(notificationId);
        } catch (error) {
            console.error('Error responding to notification:', error);
        }
    };

    const handleDelete = async (notificationId) => {
        try {
            await axios.delete(`${BaseUrl}/deletenotification/${notificationId}`);
            slideOutAndRemove(notificationId);
        } catch (error) {
            console.error('Error deleting notification:', error);
        }
    };

    const slideOutAndRemove = (notificationId) => {
        const newNotifications = notifications.map(notification =>
            notification.id === notificationId ? { ...notification, slideOut: true } : notification
        );
        setNotifications(newNotifications);

        setTimeout(() => {
            setNotifications(newNotifications.filter(notification => notification.id !== notificationId));
        }, 300); // Adjust timing as needed
    };

    const unreadCount = notifications.filter(notification => notification.response === null).length;

    return (
        <div className="mobile-notification-component" style={{ position: 'relative', display: 'inline-block'}}>
            <div onClick={handleNotificationClick} className="notification-icon" style={{ cursor: 'pointer' }}>
                <i className='fas fa-bell' style={{
                    // marginRight: "8px",
                    fontSize: "18px",
                    color: "#fff",
                    
                }}></i>
                {unreadCount > 0 && <span className="notification-count" style={{
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    color: '#fff',
                    padding: '2px 8px',
                    position: 'absolute',
                    top: '-5px',
                    right: '-10px',
                    fontSize: '12px'
                }}>{unreadCount}</span>}
            </div>
            {showNotifications && (
                <div ref={pageRef} className="notification-page" style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100vh',
                    backgroundColor: '#000',
                    zIndex: 1000,
                    overflowY: 'auto',
                    padding: '20px',
                    boxSizing: 'border-box',
                    color: '#fff',
                }}>
                    <button onClick={() => setShowNotifications(false)} style={{
                        position: 'absolute',
                        top: '4px',
                        right: '20px',
                        fontSize: '40px',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        color: '#fff',
                        paddingTop:'50px'
                    }}>×</button>
                    {notifications.length > 0 ? (
                        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop:'5rem' }}>
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Message</th>
                                    <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notifications.map(notification => (
                                    <tr key={notification.id} className={notification.slideOut ? 'slide-out' : ''} style={{
                                        transition: 'transform 0.3s ease',
                                        transform: notification.slideOut ? 'translateX(100%)' : 'none'
                                    }}>
                                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{notification.message}</td>
                                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                            {notification.response === null ? (
                                                <div style={{ display: "flex" }}>
                                                    <button onClick={() => handleResponse(notification.id, "Yes")} style={{ marginRight: '5px' }}>Yes</button>
                                                    <button onClick={() => handleResponse(notification.id, "No")} style={{ marginRight: '5px' }}>No</button>
                                                    {/* <button onClick={() => handleDelete(notification.id)}>Delete</button> */}
                                                </div>
                                            ) : (
                                                <span>Response: {notification.response}</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p style={{ textAlign: 'center', color: '#fff', marginTop:'5rem' }}>No notifications</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default MobileNotification;
