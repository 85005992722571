import React from "react";
import Search from "./Search";
import Toast, { showToast } from "./Toast.jsx";

function PropertiesBanner() {
  return (
    <div
      className="hero page-inner overlay"
      style={{ backgroundImage: 'url("images/hero_bg_1.jpg")' }}
    >
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-9 text-center">
            <h1
              className="heading"
              //   data-aos="fade-up"
            >
              Easiest way to find your dream home
            </h1>
            {/* <Search/> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertiesBanner;
