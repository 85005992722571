import React from "react";
import Toast, { showToast } from "./Toast.jsx";

function AboutUsContent() {
  return (
    <div>
      <div className="section">
        <div className="container">
          <div className="row text-left mb-5">
            <div className="col-12">
              <h2 className="font-weight-bold heading text-primary mb-4">
                About Us
              </h2>
            </div>
            <div className="col-lg-6">
              <p className="">
              Hemanth Services And Solutions in Legal (H.S.A.S In Legal) formed by Hemanth Services And Solutions which is incorporated in 2019 in bangalore which company registered under MSME Act as per the law of india and serving customers with keeping a respective ethics on customer service. We have flexible fee structure which can be negotiated with customers based on extent of services needs. We wish that our services be mutually profitable.
              </p>
              {/* <p className="text-black-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
                saepe, explicabo nihil. Est, autem error cumque ipsum
                repellendus veniam sed blanditiis unde ullam maxime veritatis
                perferendis cupiditate, at non esse!
              </p>
              <p className="text-black-50">
                Enim, nisi labore exercitationem facere cupiditate nobis quod
                autem veritatis quis minima expedita. Cumque odio illo iusto
                reiciendis, labore impedit omnis, nihil aut atque, facilis
                necessitatibus asperiores porro qui nam.
              </p> */}
            </div>
            <div className="col-lg-6">
              <p className="">
              We provide best plans in insurance, loan according to your family, financial, Health status, Age and commitments. Consultation with Advisors is very important before investing.
              </p>
              {/* <p className="text-black-50">
                Enim, nisi labore exercitationem facere cupiditate nobis quod
                autem veritatis quis minima expedita. Cumque odio illo iusto
                reiciendis, labore impedit omnis, nihil aut atque, facilis
                necessitatibus asperiores porro qui nam.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsContent;
