import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import { BaseUrl } from "./BaseUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backgroundImage from "./bg1.jpg";

function Team() {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    // Fetch team members data from the API
    axios
      .get(`${BaseUrl}/getallteammembers`)
      .then((response) => {
        if (response.data.success) {
          setTeamMembers(response.data.results);
          
        }
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the team members data!",
          error
        );
      });
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div>
      {teamMembers?.length > 0 &&(
      <div className="section section-5 ">
        
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-lg-12 mb-2">
              <h2 className="font-weight-bold heading text-primary mb-4">
                Our Team
              </h2>
              <p className="">
                Meet our dedicated team who work tirelessly to deliver the best
                service.
              </p>
            </div>
          </div>
          <Slider {...settings}>
            {teamMembers.map((member) => (
              <div key={member.id} className="col-12 mb-5">
                <div
                  className="person shadow-sm p-3 mb-5 rounded"
                  style={{
                    margin: "0 15px",
                    height: "400px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src={member.image}
                      alt="Image"
                      className="img-fluid rounded-circle"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        marginTop: "-5px",
                      }}
                    />
                  </div>
                  <div className="person-contents mt-4">
                    <h3 className="mb-0 text-center">{member.name}</h3>
                    <span className="meta d-block mb-3 text-center">
                      {member.role}
                    </span>
                    <p className="small text-center">{member.description}</p>
                    <ul className="social list-unstyled list-inline dark-hover text-center">
                      {member.twitter_url && (
                        <li className="list-inline-item">
                          <a href={member.twitter_url} target="_blank">
                            <span className="icon-twitter" />
                          </a>
                        </li>
                      )}
                      {member.facebook_url && (
                        <li className="list-inline-item">
                          <a href={member.facebook_url} target="_blank">
                            <span className="icon-facebook" />
                          </a>
                        </li>
                      )}
                      {member.linkedin_url && (
                        <li className="list-inline-item">
                          <a href={member.linkedin_url} target="_blank">
                            <span className="icon-linkedin" />
                          </a>
                        </li>
                      )}
                      {member.instagram_url && (
                        <li className="list-inline-item">
                          <a href={member.instagram_url} target="_blank">
                            <span className="icon-instagram" />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
     
      </div>
         )}
    </div>
  );
}

export default Team;
