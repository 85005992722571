import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../redux/actions/userActions";
import { BaseUrl } from "../BaseUrl";
import { isValidMobile, isValidPrice, isValidOccupation } from "../../utils/validations";
import Footer from '../Footer';
import Header from '../Header';
import { browserName, osName } from "react-device-detect";
import { Country, State, City } from "country-state-city";
import occupations from "./occupations";
import Toast, { showToast } from "../Toast.jsx";

const PostPropSignup = () => {
  const [signupInfo, setSignupInfo] = useState({
    mobile: "",
    name: "",
    email: "",
    password: "",
    country:"",
    state:"",
    city:"",
    occupation: "",
    gender: "",
    mobile1:"",
  });

  const [ipAddress, setIpAddress] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [logoError, setLogoError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    const fetchLogo = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/getlogodetails`);
        if (response.data.success) {
          setLogoUrl(response.data.results.logo);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchIpAddress();
    fetchLogo();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "usertype") {
      const usertype = checked
        ? [...signupInfo.usertype, value]
        : signupInfo.usertype.filter(type => type !== value);

      setSignupInfo({
        ...signupInfo,
        usertype,
      });
    } else {
      setSignupInfo({
        ...signupInfo,
        [name]: value,
      });
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  };


  const handleProfessionChange = (event) => {
    setSignupInfo({
      ...signupInfo,
      occupation: event.target.value,
    });
  };
  
  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    const country = Country.getAllCountries().find(
      (c) => c.isoCode === countryCode
    );
    setSelectedCountry(country);
    setSelectedState(null); // Reset state selection
    setSelectedCity(null); // Reset city selection

    setSignupInfo({
      ...signupInfo,
      country: country.name,
      state: "",
      city: "",
    });
  };

  const handleStateChange = (event) => {
    const stateCode = event.target.value;
    const state = State.getStatesOfCountry(selectedCountry.isoCode).find(
      (s) => s.isoCode === stateCode
    );
    setSelectedState(state);
    setSelectedCity(null); // Reset city selection

    setSignupInfo({
      ...signupInfo,
      state: state.name,
      city: "",
    });
  };

  const handleCityChange = (event) => {
    const cityName = event.target.value;
    const city = City.getCitiesOfState(
      selectedCountry.isoCode,
      selectedState.isoCode
    ).find((c) => c.name === cityName);
    setSelectedCity(city);

    setSignupInfo({
      ...signupInfo,
      city: city.name,
    });
  };


  const handleSignup = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (!isValidMobile(signupInfo.mobile)) {
        showToast.warning("Please enter a valid 10-digit mobile number");
        return;
      }
      if (!isValidMobile(signupInfo.mobile1)) {
        showToast.warning("Please enter a valid 10-digit mobile number");
        return;
      }
      if (!isValidEmail(signupInfo.email)) {
        showToast.warning("Please enter a valid email address");
        return;
      }
      if (!isValidOccupation(signupInfo.occupation)) {
        showToast.warning("Please select an occupation");
        return;
      }

      const signupData = {
        ...signupInfo,
        ip: ipAddress,
        browser: browserName,
        os: osName,
      };

      const response = await axios.post(`${BaseUrl}/enteruserdetailsbeforepropertypost`, signupData);
      showToast.success(response.data.message);

      if (response.status === 201 && response.data.message === "User registered successfully") {
        const { userId, emailId, usertoken } = response.data;

        localStorage.setItem("userId", userId);
        localStorage.setItem("email", emailId);
        localStorage.setItem("usertoken", usertoken);

        dispatch(
          setUserDetails({
            id: userId,
            name: signupInfo.name,
            email: signupInfo.email,
            mobile: signupInfo.mobile,
            occupation: signupInfo.occupation,
            gender: signupInfo.gender,
            city: signupInfo.city, 
            state: signupInfo.state,
            country: signupInfo.country,
          })
        );

        setSignupInfo({
          mobile: "",
          name: "",
          email: "",
          password: "",
          country: "",
          state: "",
          city:" ",
          occupation: "",
          gender: "",
          mobile1: "",
        });

        navigate("/addproperties");
      }
    } catch (error) {
      console.error("Error during signup:", error);
    }finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Header />
      <main className="section">
        <div className="container mt120">
          <div className="row">
            <div className="col-lg-12">
              <div className="mdlcnt">
                <div className="row">
                  <div className="col-lg-4 mb-5 mb-lg-0">
                    <div className="text-center mt-4">
                      {logoUrl && !logoError ? (
                        <img 
                          src={logoUrl} 
                          alt="Property Icon" 
                          onLoad={() => console.log("Logo loaded successfully")}
                          onError={(e) => {
                            console.error("Error loading logo:", e);
                            setLogoError(true);
                          }} 
                          style={{ maxWidth: "40%", maxHeight: "50%", objectFit: "contain", }}
                        />
                      ) : (
                        <div>HSAS</div>
                      )}
                    </div>
                    <div className="mt-4">
                      <h6 className="hd">Sign up For Users</h6>
                      <ul className="checklist">
                        <li title="Zero Brokerage.">Zero Brokerage.</li>
                        <li title="Thousands of new listings daily.">Thousands of new listings daily.</li>
                        <li title="100 Cr+ Brokerage saved monthly.">100 Cr+ Brokerage saved monthly.</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="mdlcnt valign">
                      <div className="row mb-1">
                        <div className="" style={{textAlign:"center"}}>
                          <h3 className="font-weight-bold heading mb-2">
                            Enter Basic Details
                          </h3>
                        </div>
                      </div>
                      <form onSubmit={handleSignup}>
                        <div className="row mt-2">
                          <div className="col-6 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="mobile"
                              placeholder="Enter Your Mobile Number"
                              onChange={handleChange}
                              value={signupInfo.mobile}
                            />
                          </div>
                          <div className="col-6 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Enter Name"
                              onChange={handleChange}
                              value={signupInfo.name}
                            />
                          </div>
                          <div className="col-6 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder="Enter Your Email Like xyz@gmail.com"
                              onChange={handleChange}
                              value={signupInfo.email}
                            />
                          </div>
                          <div className="col-6 mb-3">
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              placeholder="Enter Your Password"
                              onChange={handleChange}
                              value={signupInfo.password}
                            />
                          </div>
                          <div className="col-4 mb-3">
                            <select
                              onChange={handleCountryChange}
                              value={selectedCountry ? selectedCountry.isoCode : ""}
                             
                              className="form-select form-control"
                              name="country"
                            >
                              <option value="">Select Country</option>
                              {Country.getAllCountries().map((country) => (
                                <option
                                  key={country.isoCode}
                                  value={country.isoCode}
                                >
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {selectedCountry && (
                            <>
                              <div className="col-4 mb-3">
                                <select
                                  onChange={handleStateChange}
                                  // value={signupInfo.state}
                                  className="form-select form-control"
                                  name="state"
                                >
                                  <option value="">Select State</option>
                                  {State.getStatesOfCountry(
                                    selectedCountry.isoCode
                                  ).map((state) => (
                                    <option
                                      key={state.isoCode}
                                      value={state.isoCode}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          )}

                          {selectedState && (
                            <>
                              <div className="col-4 mb-3">
                                <select
                                  onChange={handleCityChange}
                                  // value={signupInfo.city}
                                  className="form-select form-control"
                                  name="city"
                                >
                                  <option value="">Select City</option>
                                  {City.getCitiesOfState(
                                    selectedCountry.isoCode,
                                    selectedState.isoCode
                                  ).map((city) => (
                                    <option key={city.name} value={city.name}>
                                      {city.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          )}
                          <div className="col-4 mb-3">
                            {/* <label>Profession</label> */}
                            <select
                              className="form-control form-select "
                              id="occupation"
                              name="occupation"
                              // placeholder={userDetails?.occupation}
                              onChange={handleProfessionChange}
                              value={signupInfo.occupation}
                            >
                              <option value="" disabled>
                                Profession
                              </option>
                              {occupations.map((occupation, index) => (
                                <option key={index} value={occupation}>
                                  {occupation}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-4 mb-3">
                          <input
                              type="text"
                              className="form-control"
                              name="mobile1"
                              placeholder="Enter Alternate Mobile number"
                              onChange={handleChange}
                              value={signupInfo.mobile1}
                            />
                          </div>
                          <div className="col-4 mb-3">
                            <select
                              className="form-select form-control"
                              name="gender"
                              onChange={handleChange}
                              value={signupInfo.gender}
                            >
                              <option value="">Select Gender</option>
                              <option value="Female">Female</option>
                              <option value="Male">Male</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                          
                          {isSubmitting ? "Submitting..." : "Continue"}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PostPropSignup;
