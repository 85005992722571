import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import { BaseUrl } from "./BaseUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TeamImages() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch team photos from the API
    axios.get(`${BaseUrl}/getteamphotos`)
      .then(response => {
        if (response.data.success) {
          setImages(response.data.results);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the team photos!", error);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <>
      {images?.length > 0 &&(
    <div style={{  padding: "50px 0" }}>
    
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-lg-6 mb-2">
            <h2 style={{ fontWeight: "bold", color: "white", marginBottom: "20px" }}>
              Team Photos
            </h2>
            <p style={{ color: "rgba(0, 0, 0, 0.5)" }}>
              A glimpse into our team's activities and moments.
            </p>
          </div>
        </div>
        <Slider {...settings}>
          {images.map(image => (
            <div key={image.id} style={{ padding: "0 15px" }}>
              <div style={{ margin: "0 15px" }}>
                <img
                  src={image.images_array}
                  alt="Team Photo"
                  style={{
                    width: "100%",
                    height: "300px",
                    objectFit: "cover",
                    borderRadius: "5px"
                  }}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
      )}
    </>
  );
}

export default TeamImages;
