import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import Logo from "../logo-pm.jpg";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BaseUrl } from "../BaseUrl";
import { setUserDetails } from "../../redux/actions/userActions";
import { isValidMobile } from "../../utils/validations";
import { browserName, osName } from "react-device-detect";
import Toast, { showToast } from "../Toast.jsx";

const Login = () => {
  const [userInfo, setUserInfo] = useState({
    mobile: "",
    password: "",
  });
  const [ipAddress, setIpAddress] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [logoError, setLogoError] = useState(false);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const handleChangeForLogin = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/getlogodetails`);
        if (response.data.success) {
          // console.log("Logo URL fetched:", response.data.results.logo);
          setLogoUrl(response.data.results.logo);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchLogo();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // if (!isValidMobile(userInfo.mobile)) {
      //   alert("Please enter a 10 digit valid mobile number");
      //   return;
      // }
      const loginData = {
        ...userInfo,
        ip: ipAddress,
        browser: browserName,
        os: osName,
      };
      const response = await axios.post(`${BaseUrl}/userlogin`, loginData);
      if (response.status === 201) {
        const { usertoken, id, email } = response.data;
        if (!localStorage.getItem("email")) {
          localStorage.setItem("email", email);
      }
        localStorage.setItem("usertoken", usertoken);
        localStorage.setItem("userId", id);
      
        const decodedToken = jwtDecode(usertoken);
        dispatch(
          setUserDetails({
            id: decodedToken._id,
            name: decodedToken.name,
            email: decodedToken.email,
            mobile: decodedToken.mobile,
            usertype: decodedToken.usertype,
            occupation: decodedToken.occupation,
            budget: decodedToken.budget,
            gender: decodedToken.gender,
            city: decodedToken.city,
            state: decodedToken.state,
            country: decodedToken.country,
          })
        );
        setUserInfo({
          mobile: "",
          password: "",
        });
        navigate("/");
      } else {
        showToast.success(response.data.message);
        setUserInfo({
          mobile: "",
          password: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header />
      <div className="section">
        <div className="container mt120">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="mdlcnt">
                <div className="row">
                  <div className="col-lg-4 mb-5 mb-lg-0 ">
                    <div className="text-center mt-4">
                    {logoUrl && !logoError ? (
                  <img
                    src={logoUrl}
                    alt="Property Icon"
                    onLoad={() => console.log("Logo loaded successfully")}
                    onError={(e) => {
                      console.error("Error loading logo:", e);
                      setLogoError(true);
                    }}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "50px",
                      objectFit: "contain",
                      marginRight:'8rem'
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100px",
                      height: "50px",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "white",
                      marginRight:"10rem"
                      
                    }}
                  >
                    HSAS
                  </div>
                )}
                    </div>
                    <div className="mt-4">
                      <h6 className="hd">Login For Users</h6>
                      <ul className="checklist">
                        <li title="Zero Brokerage.">Zero Brokerage.</li>
                        <li title="Thousands of new listings daily.">
                          Thousands of new listings daily.
                        </li>
                        <li title="100 Cr+ Brokerage saved monthly.">
                          100 Cr+ Brokerage saved monthly.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="mdlcnt valign">
                      <div className="row mb-3">
                        <div className="col-lg-6 valign">
                          <h2 className="font-weight-bold text-primary heading mb-2">
                            Login
                          </h2>
                          <p className="mb-3">Welcome Back</p>
                        </div>
                        <div className="col-lg-6 text-lg-end">
                          <a href="/signup" className="text-right text-white">
                            Create Account
                          </a>
                        </div>
                      </div>
                      <form onSubmit={handleLogin} autoComplete="off">
                        <div className="row mt-2">
                          <div className="col-12 mb-3">
                            <label>Enter Mobile Number / Email</label>
                            <input
                              type="text"
                              name="mobile"
                              className="form-control"
                              placeholder="Enter Your Mobile Number / Email"
                              onChange={handleChangeForLogin}
                              value={userInfo.mobile}
                            />
                            {/* <div className="mt-1 fnth2">
                              <small>
                                <input
                                  type="checkbox"
                                  className="form-check-input mt-1"
                                />{" "}
                                Get Updates on WhatsApp
                              </small>
                            </div> */}
                          </div>
                          <div className="col-12 mb-3">
                            <label>Enter Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              placeholder="Enter Password"
                              onChange={handleChangeForLogin}
                              value={userInfo.password}
                              autoComplete="new-password"
                            />
                          </div>
                          <div className="row">
                            <div className="text-center">
                              <input
                                type="submit"
                                value="Login"
                                className="btn btn-primary col-6"
                              />
                            </div>

                            <div className="row">
                              {/* <div className="col-lg-9">
                                <a href="/signup" className="text-right fnth1 text-white">
                                  {" "}
                                  Create Account{" "}
                                </a>
                              </div> */}
                              <div className="col-lg-9">
                                <a className="text-right fnth1 text-white" href="/forgotpassword">
                                  Forgot password?
                                </a>
                              </div>
                              <div className="mt-3">
                                <small>
                                  By continuing, you agree to our{" "}
                                  <Link to="/terms-condition"> <strong>Terms &amp; Conditions</strong></Link>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
