import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import { BaseUrl } from './BaseUrl';
// import './AdvertisementSlider.css';
import { isPlatform } from "@ionic/react";   
import Toast, { showToast } from "./Toast.jsx";

const AdvertisementSlider = () => {
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isIOS, setIsIOS] = useState(false); 

  const fetchAds = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/getallads`); // Replace with your API endpoint
      if (response.data.success) {
        setAds(response.data.ads);
      } else {
        console.error('Failed to fetch ads:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching ads:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAds();
    if (isPlatform("ios")) {
      setIsIOS(true);
    }
  }, []);

  // console.log(isIOS,"plaeform")

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hide previous and next arrows
  };

  if (loading) {
    return <div></div>; // Optional loading indicator
  }

  return (
    <div className={`advertisement-slider mobile-view ${isIOS ? "ios-nav" : ""}`} >
      <Slider {...settings}>
        {ads.map((ad, index) => (
          <div key={index} className="ad-slide">
            <a href={ad.url} target="_blank" rel="noopener noreferrer">
              <img src={ad.image} alt={`Advertisement ${index + 1}`} className="ad-image"  style={{height:'150px', objectFit:'cover'}}/>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AdvertisementSlider;
